@import "src/styles/colors";
@import "src/styles/typography";
@import "src/styles/mixins";

.peers-switch {
  display: flex;
  flex-direction: row;
  @extend %font-md;
  color: $secondary-comet;
  letter-spacing: -1px;
  text-transform: uppercase;
  line-height: 25px;

  .peers-label {
    cursor: pointer;
    margin-right: 10px;
    width: 80px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;


    .rtl & {
      margin-right: 0;
      margin-left: 10px;
    }

    &.selected {
      border-bottom: 4px solid $primary-cerulean;
      pointer-events: none;
      opacity: 0.6;
    }
  }
}