@import "src/styles/colors";
@import "src/styles/typography";
@import "src/styles/mixins";

.options-bar {
  background: linear-gradient(180deg, #023C7F 0%, #10519E 100%);
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
  padding: 20px 12px;
  display: flex;
  align-items: center;
  opacity: 0;
  z-index: -1;
  transition: .1s opacity;

  &.active {
    opacity: 1;
    z-index: 1;
  }

  .filter-container {
    display: flex;
    cursor: pointer;
    color: $white;
    align-items: center;
    margin-right: 54px;
    overflow: hidden;

    .rtl & {
      margin-right: 0;
      margin-left: 54px;
    }

    .icon-filter path {
      stroke: $white;
    }

    .filter {
      @extend %font-weight-bold;
      font-size: 14px;
      padding: 0 6px;
      line-height: 24px;
    }

    .label {
      @include ellipse-text;
      font-size: 12px;
      margin-top: 2px;
    }
  }

  .show-opportunities {
    cursor: pointer;

    display: flex;
    align-items: center;
    color: $white;

    .text {
      padding-left: 6px;

      .rtl & {
        padding-left: 0;
        padding-right: 6px;
      }
    }
  }

  .close-icon-container {
    display: flex;
    justify-content: flex-end;
    flex: 1;

    .icon-close {
      cursor: pointer;
    }
  }
}
