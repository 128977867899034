@import "src/styles/colors";
@import "src/styles/typography";

.tooltip {
  display: flex;
  flex-direction: column;
  background-color: getColor($white, 0.85);
  border-radius: 4px;
  border: 1px solid $black;
  padding: 4px;

  span {
    @extend %font-weight-regular;
    font-size: 10px;
    line-height: 10px;
    padding: 2px;
  }
}

.similar-sales-line {
  path {
    stroke: $secondary-summer-green;
    stroke-width: 2px;
  }

  .recharts-line-dots {
    .square-dot {
      fill: $secondary-summer-green;
      width: 10px;
      height: 10px;
      stroke-width: 2px;
    }
  }
}

.sales-line {
  path {
    stroke: $secondary-regal-blue;
    stroke-width: 2px;
  }

  .recharts-line-dots {
    circle {
      fill: $secondary-regal-blue;
      stroke: $secondary-regal-blue;
      width: 10px;
      height: 10px;
      stroke-width: 5px;
    }
  }

}

.recharts-cartesian-axis-tick {
  font-size: 10px;
  color: $secondary-slate-gray;
}

.recharts-layer {
  .recharts-label-list {
    text {
      font-size: 10px;
      fill: $secondary-comet;
    }
  }
}

.opportunities-sales-chart {
  display: flex;
  flex-direction: column;

  .opportunities-chart-title {
    @extend %font-weight-medium;
    color: $primary-blue;
    font-size: 12px;
    margin: 0 25px 20px;

  }

  .recharts-wrapper {
    .recharts-surface{
      .rtl & {
        margin-left: 10px;
      }
    }
    .recharts-legend-wrapper {
      padding-left: 40px;

      .rtl & {
        padding-left: 0;
        padding-right: 40px;
      }
    }
  }
}

.recharts-legend-item {
  width: fit-content;
}
