@import 'src/styles/colors';

.side-menu {
  display: flex;
  flex: 1;
  flex-direction: column;
  position: absolute;
  margin: 0;
  padding: 0;
  left: 0;
  width: 240px;
  background: $primary_blue;
  transition: width .1s linear;
  height: 100%;
  z-index: 1000;

  .rtl & {
    right: 0;
  }

  &.collapsed {
     width: 90px;

    .side-menu-content-wrapper {
      width: 90px;
    }

    .separator {
      background-color: getColor($gray, 0);
    }
  }

  &.hidden {
    max-width: 12px;

    .side-menu-content-wrapper {
      max-width: 12px;
    }
  }

  .side-menu-content {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100%;
  }

  .side-menu-content-wrapper {
    display: flex;
    flex: 1;
    width: 240px;
    transition: width .1s linear;
    height: 100%;
  }

  .side-menu-header {
    display: flex;
    flex-direction: column;
    height: 16%;
    padding: 30px 0 20px 0;
    justify-content: space-between;

    .icon-logo {
      margin-left: 26px;

      .rtl & {
        margin-left: 0;
        margin-right: 26px;
      }
    }
  }

  .side-menu-main {
    display: flex;
    flex-direction: column;
    padding: 10px 0;
  }

  #side-menu-myopportunities {
    margin: 10px 0;
  }

  .side-menu-settings {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-top: 10px;
  }

  .side-menu-info {
    display: flex;
    flex-direction: column;
    padding: 10px 0;
    color: $white;
    .user {
      display: flex;
      align-items: center;
      justify-content: center;
      color: getColor($white, 0.7);
      .alias {
        max-width: 200px;
        font-size: 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .version {
      display: flex;
      justify-content: center;
      color: getColor($white, 0.7);
      font-size: 10px;
      padding: 10px 0;
    }
  }

  .side-image-container {
    display: flex;
    min-height: 50px;
    flex-direction: row-reverse;
    padding-block-end: 10px;
    &.expended {
      width: 240px;
    }
    .rtl & {
      transform: rotateY(180deg);
      flex-direction: row;
    }
  }

  .separator {
    height: 1px;
    min-height: 1px;
    background-color: getColor($gray, 0.2);
  }

  .side-menu-expander {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: getColor($cyan-blue, 0.8);
    position: absolute;
    top:70px;
    right:-10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .4s ease;
    z-index: 10000;

    &:hover {
      transform: scale(1.2);
    }

    .expand-arrow {
      height: 11px;
      width: 11px;

      path {
        fill: $white;
      }
    }
  }
}


@media screen and (width <= 1600px) {
  .side-menu {
    position: relative;
  }
}

@media screen and (height <= 780px) {
  .side-menu {
    & .side-image-container {
      display: none;
    }
    & .side-menu-settings {
      padding-bottom: 10px;
    }
  }
}

@media screen and (height <= 680px) {
  .side-menu {
   & .button-side-menu {
     height: 40px;
   }
  }
}
