@import "src/styles/colors";
@import "src/styles/typography";

.reason-filter {
  width: 100%;
  border-bottom: 2px solid $primary-blue;
  display: flex;
  justify-content: space-between;
  background-color: $white;
  align-items: center;
  padding: 14px;
  transition: .2s all ease-in-out;
  cursor: pointer;

  &.active {
    border-bottom: 2px solid $primary-cerulean;
    background-color: #E6F7FC;
    box-shadow: 0 0 4px 0 getColor($black,0.1);
  }
  .label {
    @extend %font-weight-medium;

    font-size: 12px;
    color: $secondary-comet;

  }

  .value {
    @extend %font-weight-bold;
    color: $secondary-comet;
    font-size: 14px;
  }
}