@import "src/styles/mixins";
@import "src/styles/typography";

.collapse {
  .panel {
    font-size: 14px;
    font-weight: bold;
    padding: 12px 0;
    &.ant-collapse-item {
      border-bottom: 1px solid $offwhite;
      .ant-collapse-header {
        display: flex;
        align-items: center;
      }
    }
  }
}
