@import 'src/styles/colors';
@import 'src/styles/_typography';

    .dev-options {
      display: flex;
      flex: 1;
      flex-direction: column;
      width: 1490px; // page-list + page-preview
      padding: 50px;
      color: $primary-blue;

      .page-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-basis: 80px;
        @extend %font-xxl;
        border-bottom: 1px solid $mid-gray;
        color: $primary-blue;
      }

      .page-container {
        display: flex;
        flex-direction: column;
        padding-top: 10px;

        .version, .contracts {
          color: $primary-blue;
        }

        .adv-options {
          .supported-env {
            .env {
              padding: 10px;
              &.active {
                color: $secondary-summer-green;
                @extend %font-weight-bold;
              }
            }
          }
          .env-button {
            margin-inline-start: 50px;
            padding: 15px;
          }
          //padding-top: 10px;
        }
      }
    }
