.progress-animation,
.empty-state-animation{
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 1000000;
  overflow: hidden;
}

.empty-state-animation {
  margin: -230px 0;
  height: 95%;
}