@import "src/styles/colors";
@import "src/styles/typography";

.findings-header-container {
  margin: 22px 0 15px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;

  .text {
    @extend %font-weight-bold;
    flex: 1;
    display: flex;
    color: $primary-blue;
    font-size: 16px;
  }

  .ant-switch {
    background-color: $secondary-comet;
  }
}