@import "src/styles/colors";
@import "src/styles/typography";

.date-button {
  display: flex;
  gap: 6px;
  align-items: center;
  cursor: pointer;
  @extend %font-weight-medium;
  color: $primary-cerulean;
  font-size: 16px;
  line-height: 16px;

  & .dates-week-label {
    @extend %font-sm;
    text-transform: uppercase;
  }

  &.uppercase {
    text-transform: uppercase;
  }

  .cb-icon g .shape-fill{
    fill: $primary-cerulean;
  }

  .cb-icon.right{
    transform: rotate(-90deg);
  }
  .cb-icon.left{
    transform: rotate(90deg);
  }
  .cb-icon.up{
    transform: rotate(180deg);
  }
  .cb-icon.none{
    display: none;
  }

}
