@import "src/styles/typography";
@import "src/styles/colors";
@import "src/styles/mixins";

.card-print-container {
  margin: 6px 10px 0 10px;
  border: 1px solid $light-gray;

  .reason-found {
    @extend %font-weight-bold;
    padding: 4px;
    border-top: 1px solid $light-gray;
  }

  .card-info-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 8px;

    .card-header {
      display: flex;

      .text {
        flex: 1;
        width: 100%;
        text-transform: uppercase;
      }

      .card-serial {
        color: $light-gray;
      }
    }

    .card-content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap:30px;
      flex: 1;

      .product-image-print {
        object-fit: contain;
        height: 80px;
        align-self: center;
      }

      .categories-container {
        flex-wrap: wrap;


        .sum-sales-compare {
          margin-top: 10px;
          display: flex;
          font-size: 12px;

          .sales {
            display: flex;
            flex-direction: column;

            span {
              color: $secondary-comet;
            }

            &.your-stores {
              margin-right: 10px;

              .rtl & {
                margin-right: 0;
                margin-left: 10px;
              }
            }
          }
        }

        .title {
          font-size: 12px;
          line-height: 15px;
          color: $secondary-comet;
          text-decoration: underline;
        }

        .category-list {
          display: flex;
          flex-direction: column;
          margin: 10px 0;
          height: max-content;

          .category {
            margin-bottom: 8px;

            .category-title {
              @include ellipse-text;
              font-size: 12px;
              line-height: 15px;
              color: $secondary-comet;
            }

            .value {
              line-height: 17px;
              font-size: 14px;
            }
          }
        }
      }
    }

  }
}

.print-chart {
  .similar-sales-line {
    .recharts-line-dots {
      .square-dot {
        fill: $secondary-summer-green;
        width: 6px;
        height: 6px;
      }
    }
  }

  .sales-line {
    .recharts-line-dots {
      .circle-dot {
        fill: $secondary-regal-blue;
        stroke-width: 0;
      }
    }
  }
}

.print-chart-axis-label {
  font-size: 8px;
}
