@import "src/styles/colors";

.reason-filter-group {
  margin-bottom: 20px;
  width: 445px;
  border-top: 8px solid;
  display: flex;
  box-shadow: 0 0 4px 0 getColor($black, 0.1);
  height: fit-content;
  flex-direction: column;

  &:last-child {
    margin-bottom : 0;
  }
}
