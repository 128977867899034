.product-image-popup-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .popup-content .product-image {
    width: 400px;
    height: 400px;
    padding: 12px;
    object-fit: contain;
  }
}