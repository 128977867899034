@import 'src/styles/colors';
@import 'src/styles/_typography';

.App {
  display: flex;
  position: relative;
  flex: 1;
  flex-direction: row;
  margin: 0;
  padding: 0;
  height: 100%;

  .icon-stone-left {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
  }

  .icon-stone-hexagon {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
  }

  .spacer {
    min-width: 240px;
  }

  .content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    position: relative;
    height: 100%;
    align-items: center;
    padding: 0 10px;
    z-index: 500;

    .page {
      display: flex;
      flex: 1;
      flex-direction: column;
      overflow-y: hidden;
      width: 1490px; // page-list + page-preview

      .page-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-basis: 80px;
        min-height: 80px;
        @extend %font-xxl;
        border-bottom: 1px solid $mid-gray;

        .title {
          line-height: 32px;
        }
      }

      .page-container {
        display: flex;
        overflow-y: hidden;
        flex: 1;

        .page-list {
          width: 534px;
          display: flex;
          flex-direction: column;
          padding-right: 16px;
          padding-bottom: 10px;
          z-index: 1000;

          .rtl &{
            padding-right: 0;
            padding-left: 16px;
          }
        }

        &.opposite-page .page-list {
          padding-right: 0;
          padding-left: 16px;

          .rtl &{
            padding-left: 0;
            padding-right: 16px;
          }
        }

        .page-preview {
          flex: 1;
          padding-bottom: 10px;
          z-index: 1000;
          position: relative;

          .preview-container {
            display: flex;
            flex-direction: column;
            position: relative;
            height: 100%;

            &.white{
              background: $white;
            }
          }
        }
      }
    }
  }
}

.App .content .opposite-page .page-container .page-list {
  padding-right: 0;
  padding-left: 16px;

  .rtl &{
    padding-right: 16px;
    padding-left: 0;
  }
}

@media screen and (width <= 1600px) {
  .App .spacer {
   display: none;
  }
}
