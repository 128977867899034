@import 'src/styles/colors';
@import 'src/styles/_typography';


.date-slider-wrapper {
  padding: 0 20px;
  min-width: 450px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  & .title{
    color:$secondary-regal-blue;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: bold;
  }
}

.slider-date-mark-wrapper {
  display: flex;
  gap: 5px;


  .slider-date-mark-label {
    color: $secondary-comet;
  }

  .slider-date-mark-value {
    min-width: 22px;
    height: 18px;
    border-radius: 2px;
    background-color: $secondary-comet;
    color:$white;
    padding: 0 2px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.disabled {
    opacity: 0.5;
  }

  &.disabled .slider-date-mark-value {
    background-color: $disabled-gray;
    color:$secondary-comet;
  }
}


.ant-slider-mark-text {
  width: max-content;
  margin-top: 2px;

  &:first-child {
    transform: translateX(-15%) !important;
  }

  &:last-child {
    transform: translateX(-85%) !important;
  }
}

.rtl .ant-slider-mark-text {
  &:first-child {
    transform: translateX(15%) !important;
  }

  &:last-child {
    transform: translateX(85%) !important;
  }
}

.ant-slider-handle {
  background-color: $primary-cerulean;
  box-shadow: 1px 1px 4px 0 rgba($primary-cerulean,0.3);
  border:none;
}
