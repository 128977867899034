@import "src/styles/mixins";
@import "src/styles/typography";

.cards-container {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.cards-header {
  @extend %font-weight-medium;
  background-color: $background-primary;
  width: 100%;
  padding: 10px;
  color: $secondary-regal-blue;
  font-size: 16px;

  &.small {
    font-size: 12px;

  }
}
