$primary-blue: #0F2643;
$keycloak-blue: #0d1b2e;
$primary-cerulean: #0BB5E2;
$primary-disable: #A3A8B2;
$secondary-summer-green: #96B89E;
$darkblue: #062645;
$cyan-blue: #385469;
$secondary-regal-blue: #00397D;
$antBlue: #1890ff;
$secondary-geyser: #D8E5E3;
$secondary-comet: #515B6E;
$secondary-slate-gray: #67808E;
$light-gray-check: #9397A2;
$gray: #E8E8E8;
$light-gray: #A8ADB6;
$mid-gray: #DADADA;
$lightgrey: #C8C8C8;
$offwhite: #DFE2E5;
$lightblue: #E9F1F4;
$background-blue: #DFE8EF;
$error: red;
$red: #E3482E;
$light-red-background: #FBE4E0;
$orange: #FB8C34;
$yellow: #FED400;
$light-orange: #FE9662;
$success: #4BB543;
$darkgreen: #1B936B;
$white: #FFFFFF;
$black: #000000;
$background-primary: #F3F4F5;
$madison: #2E485D;
$elf-green: #1B936B;
$store-gray: #8EA3B4;
$light-blue: #E6F7FC;
$disabled-gray: #E6E6E6;

@function getColor($color, $opacity) {
  @return rgba($color, $opacity);
}
