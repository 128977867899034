@import "src/styles/colors";
@import "src/styles/typography";
@import "src/styles/mixins";

.rate-comparison-card-container {
  position: relative;

  .rate-comparison-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    padding: 17px 28px 17px 17px;
    min-height: 270px;
    width: 200px;

    .progress-animation {
      position: absolute;
      height: 50%;
    }

    .chart-line-reference, .see-details .text, .sub-text {
      white-space: nowrap;
    }

    .top-container {
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: flex-start;

      .header {
        display: flex;
        letter-spacing: 0;
        @extend %font-md;
        line-height: 19px;
        text-transform: lowercase;
        color: $secondary-regal-blue;
      }

      .rate {
        @extend %font-2xl;
        @extend %font-weight-medium;
        color: $secondary-regal-blue;
        letter-spacing: 0.5px;
        line-height: 50px;
        margin: 0;
      }

      .sub-text {
        border-radius: 4px;
        background-color: $background-blue;
        color: $secondary-regal-blue;
        @extend %font-sm;
        @extend %font-weight-bold;
        width: fit-content;
        letter-spacing: -0.27px;
        padding: 4px;
        text-transform: lowercase;
      }
    }

    .bottom-container {
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: flex-end;

      .chart-legends {
        display: flex;
        flex-direction: column;

        .legend {
          display: flex;
          flex-direction: row;
          line-height: 17px;
          align-items: center;

          .chart-line-reference {
            width: 18px;
            height: 0;
            margin-right: 7px;
          }

          &.my-stores {
            .chart-line-reference {
              border-bottom: 4px solid $secondary-regal-blue;
            }

            .title {
              color: $secondary-regal-blue;
              @extend %font-sm;
            }
          }

          &.chain-wide {
            .chart-line-reference {
              border-bottom: 4px dashed $light-orange;
            }

            .title {
              color: $light-orange;
              @extend %font-sm;
            }
          }
        }
      }

    .separator {
      color: $gray;
      border: 1px solid getColor($gray, 0.5);
      margin: 10px 0 30px 0;
    }

      .footer {
        display: flex;
        justify-content: space-between;
        width: 100%;
        line-height: 20px;
        align-items: flex-end;

        .see-details {
          position: relative;
        }

        .footer-info {
          @extend %font-md;
          padding: 0;
          margin: 0;
          opacity: 0.7;
        }
      }

      .see-details-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        line-height: 12px;
        cursor: pointer;
      }

      .see-details-text {
        @extend %font-sm;
        color: $secondary-comet;
        text-transform: uppercase;
        margin-right: 3px;
      }

      .see-details-icon {
        transform: rotate(90deg);
        width: 12px;
        height: 12px;

        path {
          fill: $red;
        }

        .rtl & {
          transform: rotate(-90deg);
        }
      }
    }
  }
}
