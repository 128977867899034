@import 'src/styles/colors';

.no-match {
  width: 40%;
  height: 100px;
  border-radius: 10px;
  background: #f5e8e8;
  margin: 100px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  text-transform: capitalize;
  color: #afafaf;
  font-weight: 600;
}
