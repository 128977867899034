@import "src/styles/colors";
@import "src/styles/typography";

.decorative-footer {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: space-between;
  background-color: $cyan-blue;
  border-radius: 4px;

  &.small {
    height: 118px;

    .text-container {
      padding-top: 28px;
      line-height: 22px;

      .text {
        @extend %font-lg;
        margin-top: 2px;
      }
    }
  }

  .text-container {
    display: flex;
    flex-direction: column;
    color: $white;
    padding-top: 42px;
    padding-left: 28px;

    .rtl & {
      padding-left: 0;
      padding-right: 28px;
    }

    .title {
      @extend %font-lg;
      @extend %font-weight-medium;
      text-transform: uppercase;
    }

    .text {
      @extend %font-md;
      margin-top: 20px;
      white-space: normal;
      line-height: 20px;
    }
  }

  .icon-responsive-illustrator {
    height: 100%;
    object-fit: contain;
  }

  svg {
    .rtl & {
      transform: scaleX(-1);
    }
  }
}
