@import "src/styles/colors";
@import "src/styles/typography";

@mixin centralize {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* NOT TO USE */
@mixin flexible($dir,$justifyContent:false,$alignItems:false) {
  display: flex;
  flex-direction: $dir;
  @if ($justifyContent) {
    justify-content: $justifyContent;
  }
  @if ($alignItems) {
    align-items: $alignItems;
  }
}

@mixin ellipse-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin cursor-pointer {
  cursor: pointer;
}

@mixin edit-text {
  border-radius: 8px;
  border: dashed 2px transparent;
  cursor: pointer;

  &:hover {
    border: dashed 2px rgba(50, 118, 147, 0.3);
  }
}

@mixin editable {
  &.editable {
    &:hover::after {
      cursor: pointer;
      position: absolute;
      content: '';
      height: 36px;
      opacity: 0.3;
      border-radius: 8px;
      border: dashed 2px $companion;
      left: -7px;
      right: 0;
      top: -6px;
    }
  }
}

@mixin edit-text-selected {
  background-color: #E4F3F9;
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}

// Disable selection
@mixin disable-selection {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Safari */
  -khtml-user-select: none;    /* Konqueror HTML */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;           /* Non-prefixed version, currently supported by Chrome and Opera */
}