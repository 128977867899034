@import "src/styles/mixins";

.video-poster-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 100%;
  height: 100%;
  position: relative;

  .poster-icon-container{
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    align-items: center;
    display: flex;
    position: absolute;
    justify-content: center;
  }
  .video-poster {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-color: $background-primary;
  }

  .video-poster-play-button {
    position: absolute;
  }

  .c-video-duration {
    position: absolute;
    right: 18px;
    bottom: 18px;
    z-index: 100;
    padding: 10px;
    color: white;
    font-size: 16px;
    line-height: 14px;
    border-radius: 2px;
    background-color: getColor($primary-blue, 0.7);
  }
}
