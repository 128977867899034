@import "src/styles/_mixins.scss";

.video-player-container {
  width: 100%;
  height: 530px;

  video {
    height: 100%;
    width: 100%;
    display: none;
  }

  .video-player-visible {
    display: inline-block;
  }
}
