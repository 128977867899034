@import "src/styles/colors";
@import "src/styles/typography";

.reason-card {
  padding: 24px 10px;
  width: 33%;

  .name {
    @extend %font-sm;
    line-height: 17px;
    letter-spacing: -0.27px;
    opacity: 0.6;
    text-wrap: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .count {
    line-height: 30px;
    @extend %font-lg-3;
  }

  .ant-progress-inner {
    background-color: #888585;
  }

  .ant-progress-bg {
    background-color: #0061C4;
  }
}

.progress-animation {
  position: absolute;
  margin: auto;
}