$fontsRoot: '../fonts/';

@font-face {
  font-family: 'Basis Grotesque';
  src: url($fontsRoot + 'basis-grotesque-pro-regular.eot');
  src: url($fontsRoot + 'basis-grotesque-pro-regular.eot?#iefix') format('embedded-opentype'),
  url($fontsRoot + 'basis-grotesque-pro-regular.woff2') format('woff2'),
  url($fontsRoot + 'basis-grotesque-pro-regular.woff') format('woff'),
  url($fontsRoot + 'basis-grotesque-pro-regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Basis Grotesque';
  src: url($fontsRoot + 'basis-grotesque-pro-medium.eot');
  src: url($fontsRoot + 'basis-grotesque-pro-medium.eot?#iefix') format('embedded-opentype'),
  url($fontsRoot + 'basis-grotesque-pro-medium.woff2') format('woff2'),
  url($fontsRoot + 'basis-grotesque-pro-medium.woff') format('woff'),
  url($fontsRoot + 'basis-grotesque-pro-medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Basis Grotesque';
  src: url($fontsRoot + 'basis-grotesque-pro-bold.eot');
  src: url($fontsRoot + 'basis-grotesque-pro-bold.eot?#iefix') format('embedded-opentype'),
  url($fontsRoot + 'basis-grotesque-pro-bold.woff2') format('woff2'),
  url($fontsRoot + 'basis-grotesque-pro-bold.woff') format('woff'),
  url($fontsRoot + 'basis-grotesque-pro-bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}