@import 'src/styles/colors';
@import "src/styles/mixins";


.categories-accordion {

  .ant-collapse-item {

    .ant-collapse-header {

      .filter-label-header {
        .title {
         font-size: 14px;
        }
        .labels {
          display: flex;
          min-height: 12px;
          flex-wrap: wrap;
          width: 500px;
          .label {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 10px;
            color: $primary-cerulean;
           padding-right: 3px;

            .rtl &{
              padding-right: 0;
              padding-left: 3px;
            }
          }
        }
      }
    }
    .ant-collapse-content{
      .ant-collapse-content-box {
       padding: 0;
      }
    }
  }
}

.categories-accordion {
  .main-category-header {
    color: $primary-cerulean;
  }
}

.categories-accordion.sub-categories {
  padding-left: 16px;
  &.rtl {
    padding-left: 0;
    padding-right: 20px;
  }

  .panel {
    padding: 6px 0;
    &:last-child{
      border-bottom: none;
    }
  }

  .sub-category-filter-options {
    padding: 4px;
    max-height: 30vh;
    overflow-y: auto;
  }

  .custom-checkbox .ant-checkbox-wrapper {
    flex-direction: row;
    padding: 0 6px;
  }
}

.no-results-categories {
  font-weight: normal;
  text-align: center;
  font-size: 12px;
}
