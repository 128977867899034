@import "../../../styles/mixins";
@import "../../../styles/colors";

.selected-reason {
  display: flex;
  flex-direction: column;
  box-shadow: 0 1px 4px 2px getColor($black, 0.1);
  padding: 10px 15px 10px 15px;
  gap: 10px
}

.reason-date {
  font-size: 20px;
  font-weight: bold;
}

.selected-reason-title {
  display: flex;
  align-items: center;
  gap: 15px;
}

.check-mark-icon-wrapper {
  border-radius: 50%;
  width: 25px;
  height: 25px;
  background-color: $secondary-summer-green;
  display: flex;
  align-items: center;
  justify-content: center;

  .reason-completed-icon {
    width: 18px;
    height:18px;
  }
  .reason-completed-icon path {
    stroke: $white;
  }
}

.selected-reason-code {
  display: flex;
  flex-direction: column;
}

.reason-title {
  font-weight: bold;
}

.reason-title,
.reason-desc {
  font-size: 12px;
}
