@import "src/styles/colors";
@import "src/styles/typography";

.modal-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;

  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  box-sizing: border-box;
  background: none;
  &.withOverlay {
    background: getColor($black, 0.6);
  }

  .content {
    position: relative;
    z-index: 10002;
    min-width: 200px;
    max-width: 100%;
    background-color: white;
    &.withOverlay {
      box-shadow: 0 0 50px 0px getColor(black,0.1);
      border-radius: 5px;
    }
  }
}
