@import "src/styles/colors";
@import "src/styles/typography";

.bar-chart-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  .chart-container {
    flex: 1;
    padding-right: 30px;
    padding-left: 2px;

    .rtl & {
      padding-right: 2px;
      padding-left: 30px;
    }
  }

  .prev-arrow {
    transform: rotate(180deg);
  }

  .right-arrow {
    visibility: hidden;

    &.visible {
      visibility: visible;
    }
  }
}

.trends-tooltip {
  border: none;
  background-color: getColor($white, 0.9);
  border-radius: 3px;
  padding: 4px 8px;
  text-transform: capitalize;
}


.recharts-tooltip-cursor {
  fill: transparent;
}

.trends-bar-chart {
  direction: ltr;

  .recharts-surface {
    .recharts-cartesian-grid .recharts-cartesian-grid-horizontal {
      line {
        stroke-width: 0.3;
        stroke: $secondary-comet;
      }
    }

    .xAxis, .yAxis {
      line {
        stroke-width: 0;
      }

      .recharts-cartesian-axis-ticks .recharts-layer text {
        @extend %font-weight-medium;
        fill: $light-gray;
        font-size: 10px;
      }
    }

    .recharts-bar .recharts-bar-rectangles {
      .recharts-rectangle {
        fill: $elf-green;
        cursor: pointer;

        &.blur {
          fill: getColor($elf-green, 0.4);
        }

        &.recharts-bar-background-rectangle {
          fill: transparent;
          pointer-events: none;

          &:last-of-type {
            fill: $madison;
          }
        }

      }
    }

    .recharts-reference-line {
      line {
        stroke: $secondary-comet;
        stroke-width: 0.3;
        stroke-dasharray: 2 2;
      }
    }
  }
}
