@import "src/styles/colors";

.indicator {
  height: 39px;
  width: 6px;
  border-radius: 3px;

  &.yellow {
    background-color: $yellow;
  }

  &.blue {
    background-color: $secondary-regal-blue;
  }

  &.none {
    background-color: $lightgrey;

  }
}