@import "src/styles/colors";
@import "src/styles/typography";
@import "src/styles/mixins";

.summary-card-container {
  border-radius: 4px;
  box-shadow: 0 0 3px 0 getColor($black, 0.1);
  padding: 14px;
  height: 142px;
  position: relative;
  margin-bottom: 14px;

  .progress-animation {
    position: absolute;
    height: 50%;
  }

  .header {
    display: flex;
    letter-spacing: 1px;
    @extend %font-md;
    @extend %font-weight-medium;
    line-height: 20px;
    text-transform: uppercase;
    align-items: center;
    margin-bottom: 7px;

    .question-mark {
      width: 16px;
      height: 16px;
      margin-left: 5px;
      cursor: pointer;
      opacity: 0.5;

      path {
        fill: #515A6F;
      }
    }
  }

  .main-content {
    display: block;
    padding: 0;

    .title-container {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      line-height: 40px;

      .title {
        @extend %font-2xl;
        @extend %font-weight-medium;
        letter-spacing: 0.5px;
        padding: 0;
        margin: 0;
      }

      svg {
        margin: 4px 0 0 10px;
      }
    }

    .sub-text {
      border-radius: 4px;
      background-color: $background-blue;
      color: $secondary-regal-blue;
      @extend %font-weight-bold;
      width: fit-content;
      padding: 4px;
      @extend %font-sm;
      letter-spacing: -0.27px;
      line-height: 15px;
      height:22px;
      margin-top: 2px;

      &.sub-text-empty {
        background: none;
      }
    }
  }

  .footer {
    width: 100%;
    line-height: 20px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .see-details {
      position: relative;
      bottom: 4px;
    }

    .footer-info {
      @extend %font-md;
      padding: 0;
      margin: 0;
      opacity: 0.7;
    }
  }


  &.open-opportunities {
    background-color: $light-red-background;

    .header {
      color: $red;
    }

    .main-content {
      .title {
        color: $red;
      }

      .sub-text {
        height: 25px;
      }
    }

    .footer {
      .footer-info {
        color: $red;
      }
    }
  }

  &.rate {
    background-color: $white;

    .main-content {
      .title {
        color: $secondary-regal-blue;
      }

      .sub-text {
        height: 25px;
      }
    }

    .footer {
      .footer-info {
        color: $secondary-regal-blue;
      }
    }
  }

  &.kpi {
    background-color: $white;
    height: 154px;


    .header {
      color: $primary-blue;
    }

    .main-content {
      .title {
        color: $secondary-regal-blue;
        line-height: 46px;
      }

      .sub-text {
        height:12px;
      }
    }

    .footer {
      align-items: flex-end;

      .footer-info {
        border-radius: 4px;
        background-color: $background-blue;
        color: $secondary-regal-blue;
        width: fit-content;
        padding: 5px;
        @extend %font-lg-2;
        letter-spacing: 0;
        line-height: 23px;;
      }
    }
  }

  .see-details-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 12px;
    cursor: pointer;
  }

  .see-details-text {
    @extend %font-sm;
    color: $secondary-comet;
    text-transform: uppercase;
    margin-right: 3px;
  }

  .see-details-icon {
    transform: rotate(90deg);
    width: 12px;
    height: 12px;

    path {
      fill: $red;
    }

    .rtl & {
      transform: rotate(-90deg);
    }
  }
}

.summary-rate-popup {
  .title {
    text-transform: capitalize;
  }

  .buttons-container {
    padding: 0;

    button.alert-button {
      width: 100%;
    }
  }
}
