@import 'src/styles/colors';

.cb-icon {
  fill: none;
  width: 20px;
  height: 20px;

  &.icon-priority-normal,
  &.icon-favorite-checked {
    color: red; // TODO change according to theme
  }

  &.active {
    > path, g > path {
      transition: transform 0.3s ease;
      fill: $primary_cerulean;
    }
  }
}

.icon-welcome,
.icon-dashboard-empty-state {
  width: 70px;
  height: 70px;
}

.icon-contact-us {
  width: 360px;
  height: 320px;
}

.share-icon {
  width: 26px;
  height: 16px;
}

.icon-no-response {
  fill: none;
  width: 34px;
  height: 28px;
}

.icon-logo {
  fill: none;
  width: 32px;
  height: 33px;
}

.icon-title-logo {
  fill: none;
  width: 122px;
  height: 33px;
}

.icon-empty-list {
  fill: none;
  width: 83px;
  height: 88px;
}

.icon-reasons {
  fill: none;
  width: 35px;
  height: 31px;
}

.icon-side-menu {
  fill: none;
  width: 24px;
  height: 24px;
}

.icon-responsive-illustrator {
}

.icon-play {
  width: 128px;
  height: 128px;
}

.icon-stone-left {
  width: 271px;
  height: 350px;
}

.icon-stone-hexagon {
  width: 439px;
  height: 325px;
}


.icon-settings {
  width: 337px;
  height: 330px;
}

.icon-filter {
  fill: none;
  width: 24px;
  height: 24px;

  &.active {
    > circle {
      fill: #E3482F;
      stroke: #F3F4F5;
    }
  }
}

.icon-close {
  width: 20px;
  height: 20px;
}

.icon-search {
  width: 24px;
  height: 24px;
}

.icon-sort {
  width: 20px;
  height: 20px;
}

.icon-print {
  width: 24px;
  height: 24px;
}

.icon-open-tab {
  width: 106px;
  height: 169px;
}

.icon-more {
  height: 36px;
  width: 36px;
}

.right-arrow {
  height: 16px;
  width: 16px;
}

.side-arrow {
  transform: rotate(90deg);

  &.shrink {
    transform: rotate(-90deg);
  }
}
