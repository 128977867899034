@import "src/styles/mixins";
@import "src/styles/colors";
@import "src/styles/typography";

.trends {
  .page-header .date-title-container {
    display: flex;
    align-items: flex-end;
    height: fit-content;

    .title {
      line-height: 32px;
    }

    .date-button {
      margin-left: 30px;

      .rtl & {
        margin-left: 0;
        margin-right: 30px;
      }
    }
  }

  .page-list {
    &.trends-list-content {
      overflow-y: hidden;

      .drill-downs-list {
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow-y: auto;
        margin-right: 2px;
        margin-left: 2px;
        justify-content: space-between;
        box-shadow: 0 0 2px getColor($black, 0.1);

        &.expended {
          overflow: hidden;
        }

        .rtl & {
          margin-left: 0;
          margin-right: 16px;
        }

        &.list-expended {
          overflow: hidden;
        }
      }

      .share-button {
        height: 40px;
        width: 10px;
        margin: 12px 0;
        opacity: 0;
      }
    }
  }

  .page-preview .preview-container {
    background-color: transparent;

    .preview-content {
      flex: 1;
      display: flex;
      flex-direction: column;
      overflow-y: scroll;
    }

    .trends-chart {
      background-color: $primary-blue;
      padding: 64px 40px 52px 20px;
    }

    .trends-empty-state-container {
      .empty-state-container {
        height: 400px;
      }
    }

    .trends-header {
      display: flex;
      flex-direction: column;

      .header-title {
        @extend %font-lg;
        @extend %font-weight-medium;
        text-transform: uppercase;
        color: $darkblue;
        padding: 23px 0 14px;
      }

      .boxes {
        display: flex;
        justify-content: flex-start;
        padding: 0 2px 16px 2px;
        gap: 24px;
      }
    }

    .decorative-footer {
      margin-top: 16px;
      flex: 1;
    }
  }
}

.trends-date-menu {
  padding: 24px 28px;
  width: 400px;

  .date-picker {
    width: 100%;

    .ant-picker-input input {
      @extend %font-lg;
    }
  }
}
