@import "src/styles/colors";
@import "src/styles/typography";

.clickable-box {
  padding: 15px 25px;
  flex:1;
  height: 155px;
  display: flex;
  flex-direction: column;
  background-color: $white;
  border-radius: 4px;
  box-shadow: 0 0 4px 0 getColor($black, 0.1);
  cursor: pointer;
  transition: .2s all ease-in-out;
  border: 2px solid transparent;
  position: relative;

  .title {
    @extend %font-weight-medium;
    @extend %font-md;
    color: $darkblue;
    line-height: 19px;
    flex: 1;
    text-transform: uppercase;
  }

  .text {
    @extend %font-md;
    color: $secondary-comet;
    line-height: 16px;
  }

  .value {
    @extend %font-weight-medium;
    color: $secondary-regal-blue;
    font-size: 40px;
    line-height: 55px;
    margin-top: 7px;
  }

  &.active {
    border: 2px solid $primary-cerulean;
    border-radius: 4px;
    background-color: $lightblue;
    box-shadow: 0 0 4px 0 getColor($black, 0.1);
  }
}