@import "src/styles/colors";
@import "src/styles/typography";

.findings {
  .page-header .date-title-container {
    display: flex;
    align-items: flex-end;
    height: fit-content;

    .title {
      line-height: 32px;
    }

    .date-button {
      margin-left: 30px;

      .rtl & {
        margin-left: 0;
        margin-right: 30px;
      }
    }
  }

  .page-container {
    .page-list {
      &.findings-list-content {
        overflow-y: hidden;

        .drill-downs-list {
          display: flex;
          flex-direction: column;
          flex: 1;
          overflow-y: auto;
          justify-content: space-between;
          box-shadow: 0 0 2px getColor($black, 0.1);
          margin-left: 2px;
          margin-right: 2px;

          &.expended {
            overflow: hidden;
          }

          .rtl & {
            margin-left: 0;
            margin-right: 16px;
          }

          &.list-expended {
            overflow: hidden;
          }
        }

        .share-button {
          height: 40px;
          width: 10px;
          margin: 12px 0;
          opacity: 0;
        }
      }
    }

    .page-preview {
      position: relative;
      .findings-empty-state{
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid getColor($black,0.1);
        border-right: 1px solid getColor($black,0.1);
        border-left: 1px solid getColor($black,0.1);
        height: 100%;
        width: 100%;
      }

      .findings-preview {
        display: flex;
        flex-direction: column;
        height: 100%;

        .findings-preview-reasons {
          display: flex;
          overflow-y: hidden;
          flex: 1;
          border-radius: 2px;
          background-color: $primary-blue;

          .findings-chart-container{
             padding-top: 20px;
             display: inline-block;
            overflow-y: auto;
          }

          .reasons-groups-container {
            display: flex;
            flex-direction: column;
            padding: 17px 1px;
            margin: 0 17px;
            flex: 1;

            .header {
              margin-bottom: 24px;
              display: flex;
              justify-content: space-between;

              .title {
                @extend %font-weight-medium;
                @extend %font-lg;
                color: $white;
                text-transform: uppercase;
              }

              .clear {
                @extend %font-weight-medium;
                @extend %font-lg;
                cursor: pointer;
                color: $primary-cerulean;

                &.no-selection {
                  color: $white;
                }
              }
            }

            .reasons-groups {
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              width: 100%;
              overflow-y: auto;
            }
          }
        }
      }
    }

  }
}

.findings-date-menu {
  padding: 24px 28px;
  width: 400px;

  .date-picker {
    width: 100%;

    .ant-picker-input input {
      font-size: 16px;
    }
  }
}
