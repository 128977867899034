@import 'src/styles/typography';
@import 'src/styles/colors';
@import "src/styles/mixins";

.wide-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  height: 60px;
  transition: witdh .1s linear;
  background: getColor($primary_cerulean, 0.9);
  border-radius: 5px;
  &.active {
    background: getColor($primary_cerulean, 0.9);
  }

  &:hover {
    background: $primary_cerulean;
  }

  .button {
    display: flex;
    background: none;
    font-size: 16px;
    color: $white;
    @extend %font-weight-bold;
    border: 0;
    cursor: pointer;
    &.active {
      color: $white;
    }
  }
}

// No selectable element
.no-selectable {
  @include disable-selection;
}
