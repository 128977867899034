@import "src/styles/colors";
@import "src/styles/typography";

.dropdown-container {
  display: flex;
  flex-direction: column;
  position: relative;

  &.disabled {
    cursor: not-allowed;
    pointer-events: none;
    box-shadow: none;
    width: max-content;

    .select-bar .arrow-icon {
      visibility: hidden;
    }

    span {
      color: $primary-blue;
    }
  }

  .select-bar {
    @extend %font-weight-regular;
    width: 100%;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    padding: 8px 20px;
    font-size: 14px;
    line-height: 20px;
    height: 44px; // same as dropdown-list
    color: $primary-cerulean;
    background-color: $background-primary;
    align-items: center;

    span {
      flex:1;
      &::first-letter {
        text-transform: capitalize;
      }
    }

    .arrow-icon .shape-fill {
      fill:$primary-cerulean;
    }
  }


  &.searchable .select-bar {
    padding: 0;
    background-color: transparent;
  }

  .search-input-wrapper {
    display: flex;
    flex:1;

    .dropdown-search-input {
      flex:1
    }
  }

  .dropdown-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 0;
    transition: .2s all;
    overflow-y: scroll;
    background-color: $background-primary;
    position: absolute;
    margin-top: 44px; // same as select-bar
    z-index: 1001;

    .dropdown-row {
      @extend %font-weight-regular;
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      padding: 9px 20px;
      border-top: 1px solid #DFE2E5;
      font-size: 14px;
      line-height: 20px;
      color: $secondary-comet;

      &.isChecked {
        color: $primary-cerulean;
      }
    }

    .dropdown-row:first-child {
      border : none;
    }

    &.isOpen {
      max-height: 50vh;
    }
  }

  &.searchable .dropdown-list {
    margin-top: 48px;
    background-color: $white;
    border-radius: 16px;

    &::-webkit-scrollbar-track {
      margin-top: 8px;
    }
  }
}



