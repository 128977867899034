@import "src/styles/colors";
@import "src/styles/typography";



.date-slider-toggle-container {
  display: flex;
  margin: 8px 25px 0 25px;

  .date-button {
    width:300px;
  }


  .oo-date-slider {
    visibility: hidden;
    margin-top: 15px;

    &.visible {
      visibility: visible;
    }

    .ant-slider:hover .ant-slider-rail,
    .ant-slider-rail {
      background-color: $white;
    }
  }
}




.open-opportunities-list-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 10px 0;


  .list-tabs-cards {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .completed-empty-state {
    padding: 24px 0;
  }
}


.preview-content-container {
  width: 100%;
  height: 100%;
  position: relative;
}