@import "src/styles/colors";
%font-family {
  font-family: "Basis Grotesque", sans-serif;
}

%font-color-primary {
  color: $primary-blue;
}

%font-weight-regular {
  font-weight: 400;
}

%font-weight-medium {
  font-weight: 500;
}

%font-weight-bold {
  font-weight: 700;
}

%font-weight-extra-bold {
  font-weight: 900;
}

%font-primary {
  @extend %font-color-primary;
  @extend %font-weight-regular;
}

%font-bold {
  @extend %font-color-primary;
  @extend %font-weight-bold;
}

%font-extra-bold {
  @extend %font-color-primary;
  @extend %font-weight-extra-bold;
}

%font-regular {
  @extend %font-color-primary;
  @extend %font-weight-regular;
}

// common class to use
.font-regular {
  @extend %font-regular;
}

.font-bold {
  @extend %font-bold;
}

.font-extra-bold {
  @extend %font-extra-bold;
}

%font-xsm {
  font-size: 10px;
}

%font-sm {
  font-size: 12px;
}

%font-md {
  font-size: 14px;
}

%font-lg {
  font-size: 16px;
}

%font-lg-2 {
  font-size: 18px;
}

%font-lg-3 {
  font-size: 22px;
}

%font-xl {
  font-size: 24px;
}

%font-xxl {
  font-size: 32px;
}

%font-2xl {
  font-size: 40px;
}


%link-states {
  &:hover,
  &:focus {
    //@extend %font-color-companion;

    text-decoration: underline;
    cursor: pointer;
  }

  &:active {
    //color: $companion-2;
    text-decoration: none;
  }

  &.disabled {
    //color: $companion-3;
    pointer-events: none;
  }
}

%button {
  @extend %font-weight-extra-bold;
  @extend %font-md;

  line-height: 20px;
  text-align: center;
}


%heading-0 {
  @extend %font-extra-bold;
  @extend %font-xl;
}

%heading-1 {
  @extend %font-extra-bold;
  @extend %font-lg;
}

%heading-2 {
  @extend %font-extra-bold;
  @extend %font-md;
}

%heading-2-2 {
  @extend %font-bold;
  @extend %font-md;
}
