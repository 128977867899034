@import "src/styles/mixins";
@import "src/styles/colors";

.reasons-wrapper-container {
  @include flexible(column, flex-start);

  width: 100%;
  height: 100%;
  background-color: white;
  align-self: flex-end;
  font-size: 5px;
  overflow: hidden;

}

.reasons-tabs-container {
  padding: 12px;
  position: -webkit-sticky;
  position: sticky;
  background-color: white;
  align-items: flex-end;
  top: 0;
  z-index: 2;
  height: fit-content;
}

.cards-preview {
  flex-grow: 1;
  overflow-y: auto;
  height: 100%;
}
