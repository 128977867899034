@import "src/styles/mixins";
@import "src/styles/typography";

.info-box {
  @include flexible(column, center, center);
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  color: $secondary-regal-blue;;
  border-color: $secondary-regal-blue;
  padding: 0 8px;

  .box-header {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &.with-icon{
      .info-title{
        margin-left: 22px;

        .rtl &{
          margin-left: 0;
          margin-right: 22px;
        }
      }
    }

    .question-mark{
      margin-left: 4px;
      color: $secondary-comet;
      border: 1px solid $secondary-comet;
      width: 19px;
      height: 19px;
      font-size: 12px;
      line-height: 17px;
      text-align: center;
      border-radius: 100%;
      opacity: 0.6;
      background-color: $background-primary;
      cursor: pointer;

      .rtl &{
        margin-left: 0;
        margin-right: 4px;
      }
    }

    .info-title {
      width: fit-content;
      font-size: 12px;
      @include ellipse-text;
      @extend %font-weight-regular;
      text-align: center;
    }
  }

  &.comparable-store {
    background-color: $secondary-summer-green;
    color: $white;
    border-color: $secondary-summer-green;

    .rtl &{
      margin-right: 0;
      margin-left: 10px;
    }

  }

  .amount {
    @extend %font-weight-medium;
  }

}

.large-info-box {
  height: 64px;
  width: 236px;

  .amount {
    line-height: 39px;
    letter-spacing: -0.44px;
    font-size: 28px;
  }

  .info-title {
    width: 220px;
    font-size: 12px;
    line-height: 17px;
  }

}
