@import "src/styles/colors";
@import "src/styles/typography";


.open-opportunities-preview-header-container {
  width: 100%;
  background-color: white;
  box-shadow: 0 0 4px 0 getColor($black, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:10px;
  padding: 0 20px;

  .top {
    justify-content: space-between;
    align-items: flex-end;
    display: flex;
    height: 50px;
  }

  .bottom {
    display: flex;
    height: 40px;
    align-items: flex-start;
  }

  .title {
    flex:0.5;
    text-transform: uppercase;
    @extend %font-lg-2;
    color:$secondary-regal-blue;
  }

  .open-opportunities-search-container {
    flex:0.5;
    border-radius: 19px;

    input {
      color: $primary-blue;
      font-size: 12px;
      height: 38px;
      background-color: $background-primary;

      ::placeholder {
        color: $light-gray;
      }
    }
  }


  .ant-breadcrumb ol {
    padding: 0;
    margin: 0;
    font-size: 14px;
    text-transform: uppercase;
    @extend %font-weight-medium;

    li {
      color: $primary-cerulean;
    }
  }

  &.red .title {
    color: $red;
  }

  &.yellow .title {
    color: $yellow;
  }

  &.green .title {
    color: $secondary-summer-green;
  }

  &.gray .title {
    color: $store-gray;
  }
}






