@import "src/styles/colors";
@import "src/styles/typography";

.c-radio-item {
  display: flex;
  cursor: pointer;
  color: white;

  &.checked {
    @extend %font-weight-bold;
    color: $primary-cerulean;

    .c-check-mark {
      background: $primary-cerulean;
      border-color: $primary-cerulean;

      &:after {
        border-color: white;
      }
    }
  }

  input {
    display: none;
  }
}

.c-check-mark {
  width: 24px;
  height: 24px;
  cursor: pointer;
  position: relative;
  border-radius: 50%;
  border: 1px solid $light-gray;

  &:after {
    content: '';
    width: 15px;
    height: 7.5px;
    position: absolute;
    left: 12px;
    top: 11px;
    border: 4px solid white;
    border-top: none;
    border-right: none;
    background: transparent;
    opacity: 0;
    transform: rotate(-45deg) translate(-2px, -10px);
    transition: opacity 0.2s ease-out;
  }

  .checked &:after {
    opacity: 1;
  }
}
