@import "src/styles/colors";
@import "src/styles/typography";

.update-password-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 28px;

  .server-message-container {
    position: relative;
    margin-top: 4px;

    .error-message, .success-message {
      position: absolute;
      font-size: 18px;

    }

    .error-message {
      color: $error;
    }

    .success-message {
      color: $success;
    }
  }

  .update-password-input {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 30px;

    &.isError {
      .input {
        border: 2px solid $error;
      }
    }

    .error-message-container {
      position: relative;

      .error-message {
        position: absolute;
        color: $error;
        font-size: 14px;
        margin-top: 4px;
      }
    }

    .input {
      height: 48px;
      padding: 8px 20px;
      border-radius: 4px;
      border: 2px solid $background-primary;
      background-color: $background-primary;
      outline: none;
      font-size: 14px;
      transition: .1s all;
    }

    .title {
      @extend %font-weight-bold;
      color: $primary-blue;
      font-size: 18px;
      margin-bottom: 10px;
    }
  }

  .update-password-button {
    @extend %font-weight-bold;

    color: white;
    font-size: 16px;
    line-height: 16px;
    background-color: $primary-cerulean;
    border-radius: 4px;
    align-self: flex-end;
    outline: none;
    border: none;
    transition: .1s all;
    margin-top: 14px;
    padding: 11px 56px;

    &:disabled {
      opacity: 0.5;
    }

  }
}