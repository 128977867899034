@import "src/styles/colors";
@import "src/styles/mixins";
@import "src/styles/typography";

.video-details {
  @include flexible(column, flex-start, flex-start);

  padding: 38px 30px;
  overflow: hidden;

  .video-details-header {
    @include flexible(column, flex-start, flex-start);

    width: 100%;

    .video-details-title {
      @extend .font-bold;
      @include ellipse-text();

      width: 100%;
      color: $primary-blue;
      margin-top: 2px;
      font-size: 18px;
      line-height: 21px;
    }

    .video-details-date {
      font-size: 14px;
      line-height: 18px;
      color: $secondary-slate-gray;
    }
  }

  .video-details-info {
    margin-top: 17px;
    text-align: left;
    color: $secondary-slate-gray;
    font-size: 14px;
    line-height: 20px;
    width: 100%;

    .rtl &{
      text-align: right;
    }
  }
}