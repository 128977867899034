@import "src/styles/colors";
@import "src/styles/mixins";

.store-breakdown-list-wrapper {
  display: flex;
  flex-direction: column;
  gap:8px;

  .store-breakdown-title {
    @extend %font-lg;
    color:$primary-blue;
    font-weight: 500;
  }

  .store-breakdown-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap:8px;
  }
}