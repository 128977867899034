@import "src/styles/colors";
@import "src/styles/typography";



.preview-filters-container {
  width: 100%;
  background-color: white;
  box-shadow: 0 0 4px 0 getColor($black, 0.1);
  display: flex;
  padding: 0 20px;
  justify-content: space-between;
  gap:20px;
  z-index: 1;

  .tabs-wrapper,
  .other-filters-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    height: 62px;
  }

  .dropdown-container {
    text-transform: uppercase;

    .dropdown-row,
    .dropdown-list .dropdown-row {
      @extend %font-weight-medium;
      @extend %font-sm;
    }

    .dropdown-list {
      width: 240px;
      left:0;
    }

    .dropdown-row {
      background-color: transparent;
      gap: 5px;
      min-width: 200px;
    }

    .c-radio-item {
      display: none;
    }
  }

  .sort-button {
    width: fit-content;
    .icon-sort {
      width: 26px;
      height: 26px;
      .fill-path {
        fill :$primary-blue;
      }
    }
  }

  .open-opportunities-filters-tabs-container {

    .ant-tabs-nav-operations {
      display: none;
    }

    .tab {
      @extend %font-md;
      color:$primary-blue;
    }

    .amount,
    .ant-tabs-ink-bar {
      @extend %font-md;
      background-color: $secondary-regal-blue;
      border-color: $secondary-regal-blue;
    }

  }

  &.red .ant-tabs-ink-bar,
  &.red .amount {
    background: $red;
    border-color: $red;
  }

  &.yellow .ant-tabs-ink-bar,
  &.yellow .amount {
    background: $yellow;
    border-color: $yellow;
  }

  &.green .ant-tabs-ink-bar,
  &.green .amount {
    background: $secondary-summer-green;
    border-color: $secondary-summer-green;
  }

  &.gray .ant-tabs-ink-bar,
  &.gray .amount {
    background: $store-gray;
    border-color: $store-gray;
  }

}
