@import 'src/styles/typography';
@import 'src/styles/colors';
@import "src/styles/mixins";

.sort-button {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  height: 30px;
  width: 180px;
  transition: witdh .1s linear;
  background: $white;
  border-radius: 5px;

  .button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    background: none;
    font-size: 12px;
    color: $primary-disable;
    @extend %font-weight-regular;
    border: 0;
    cursor: pointer;
    &.selected {
      color: $primary_cerulean;
      svg {
        path:nth-child(2) {
          fill: $primary_cerulean;
        }
      }
    }
  }
}

// No selectable element
.no-selectable {
  @include disable-selection;
}
