@import "src/styles/mixins";
@import "src/styles/colors";
@import "src/styles/typography";

@keyframes delayedFadeIn {
  0% {
    opacity: 0;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.empty-state-container {
  display: flex;
  flex-direction: column;
  align-self: center;
  flex: 1;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: 0 24px;
  overflow: hidden;
}

.empty-state-title {
  font-size: 18px;
  color: $primary-blue;
  line-height: 25px;
  text-align: center;
  margin-top: 26px;
  &.light {
    color: $white;
  }
  &.with-animation {
    margin-top: 0;
    @extend %font-weight-bold;
    font-size: 24px;
  }
}

.empty-state-text {
  font-size: 16px;
  color: $secondary-comet;
  line-height: 22px;
  text-align: center;
  margin-top: 10px;
  white-space: pre-wrap;
  word-break: break-word;

  &.light {
    color: $primary-cerulean;
    @extend %font-weight-bold;
  }

  &.clickable{
    margin-top: 0;
    cursor: pointer;
    text-decoration: underline;
    color: $primary-cerulean;
  }
}

.empty-state-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 10px;
  z-index: 1000000;

  .empty-state-footer-title {
    font-size: 16px;
  }
}