@import "src/styles/colors";
@import "src/styles/typography";

.drill-down-list-container {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 195px;
  overflow: hidden;

  .cards-container {
    .empty-state-container {
      overflow-y: scroll;
      height: fit-content;
      min-height: 100%;

      .icon-empty-list {
        width: 60px;
        height: 60px;
      }
    }

    .virtuoso-list {
      max-height: 100%;

      div button {
        outline: none;
        width: 100%;
        border: none;
        padding: 0;
        background: transparent;

        .expended & {
          position: sticky;
          bottom: 0;
        }
      }
    }
  }
}

.footer-button {
  width: 100%;
  background-color: $background-primary;
  font-size: 14px;
  color: $secondary-comet;
  line-height: 20px;
  text-align: center;
  padding: 14px 0;
  border-radius: 0 0 2px 2px;
  cursor: pointer;
  bottom: 0;
  display: flex;
  border-top: 1px solid getColor($black, 0.1);
  border-bottom: 1px solid getColor($black, 0.1);
  justify-content: center;
  align-items: center;

  .cb-icon {
    width: 12px;
    height: 12px;
    transform: rotate(90deg);

    path {
      fill: $red;
    }
  }

  &.expended {
    @extend %font-weight-bold;
    color: $primary-cerulean;
    position: sticky;

    .cb-icon {
      opacity: 0;
    }
  }
}
