@import "src/styles/colors";
@import "src/styles/typography";


.open-opportunity-card-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 22px;
  box-shadow: 0 0 4px 0 getColor($black, 0.1);
  background-color: $white;
  border-radius: 4px;
  height: 80px;
  width: 100%;
  cursor: pointer;
  border: 2px solid transparent;
  transition: .2s all ease-in-out;
  gap: 8px;
  position: relative;


  &.active {
    border: 2px solid $primary-cerulean;
    background-color: $light-blue;
    pointer-events: none;
  }

  &.disabled{
    cursor: auto;
    pointer-events: none;
  }


  .card-value {
    @extend %font-xxl;
    color: $secondary-regal-blue;
  }


  .card-text {
    @extend %font-md;
    flex:1;
    color: $primary-blue;
    text-transform: uppercase;
  }


  &.red .card-value {
    color: $red;
  }

  &.yellow .card-value {
    color: $yellow;
  }

  &.green .card-value {
    color: $secondary-summer-green;
  }

  &.gray .card-value {
    color: $store-gray;
  }


  .open-opportunities-card-spinner {
    transform: translateX(35%);
  }

}