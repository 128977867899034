@import "src/styles/colors";
@import "src/styles/typography";
@import "src/styles/mixins";

.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: $keycloak-blue;
  flex-direction: column;

  #loading-progress {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 80%;
    @extend %font-lg;
    @extend %font-weight-regular;
    text-align: center;
    color: $white;

    .loading-countdown {
      margin-top: 20px;
      @extend %font-weight-bold;
    }

    .progress-bar {
      display: flex;
      flex-direction: row;
      height: 20%;
      width: 100%;
      position: relative;
    }

    .wide-button {
      width: 200px;
    }

    .contact-us {
      display: flex;
      flex-direction: row;
      position: absolute;
      bottom: 0;

      .need-support {
        padding: 10px;
      }
    }
  }

  .loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 48px;
  }
}
