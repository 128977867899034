@import "src/styles/colors";
@import "src/styles/mixins";
@import "src/styles/typography";

.preview-header-container {
  @include flexible(row);
  min-height: 114px;
  width: 100%;

  background-color: white;
  box-shadow: 0 0 4px 0 getColor(#000000, 0.1);

  &.no-issue-found .preview-details-container .preview-header-footer{
    .cb-icon path{
      fill: $lightgrey;
    }
  }

  &.ia .preview-details-container .preview-header-footer{
    .preview-header-footer-text {
      color: $secondary-summer-green;
    }
    .cb-icon path {
      fill: $secondary-summer-green;
    }
  }
}


.preview-header-top {
  @include flexible(row, flex-start, flex-start);

  padding-top: 24px;
  padding-left: 36px;

  .rtl &{
    padding-left: 0;
    padding-right: 36px;
  }
}

.preview-header-footer {
  @include flexible(row, flex-start);

  padding-bottom: 17px;
  padding-left: 29px;

  .rtl &{
    padding-left: 0;
    padding-right: 29px;
  }
}

.preview-header-top-text-container {
  @include flexible(column, flex-start);

  margin-left: 16px;

  .rtl &{
    margin-left: 0;
    margin-right: 16px;
  }
  .preview-title-container{
    display: flex;
    .preview-header-title {
      @include ellipse-text;

      @extend %font-color-primary;
      @extend %font-weight-bold;

      display: flex;
      line-height: 24px;
      font-size: 16px;
      text-align: left;
      letter-spacing: 0;

      .rtl &{
        text-align: right;
      }
    }
    .expired-task {
      display: inline-flex;
      border-radius: 2px;
      margin: 0 8px;
      background-color: getColor(#df462e, 0.15);
      text-align: center;
      align-items: center;
      height: fit-content;

      span {
        @extend %font-weight-medium;
        color: getColor($red, 0.8);
        font-size: 12px;
        line-height: 12px;
        padding: 4px;


      }
    }

  }
}


.preview-header-id {
  @extend %font-weight-medium;

  display: flex;
  font-size: 12px;
  color: getColor($primary-blue, 0.5);
  text-align: left;
  letter-spacing: 0.18px;
  line-height: 12px;

  .rtl &{
    text-align: right;
  }
}

.product-image {
  border-radius: 8px;
  object-fit: cover;
  cursor: pointer;
  width: 90px;
  height: 90px;
}

.share-container {
  @include flexible(column, center, center);

  cursor: pointer;
  margin-left: 30px;
  height: 46px;
  background-color: transparent;
  outline: none;
  border: none;
  width: 55px;

  .rtl &{
    margin-left: 0;
    margin-right: 30px;
  }
  &.share-text {
    @extend %font-weight-bold;

    font-size: 12px;
    color: #102540;
  }
}

.preview-header-id-text::before {
  white-space: pre;
  content: " ";
}

.preview-header-footer-text {
  @include ellipse-text;

  @extend %font-weight-medium;

  color: $secondary-slate-gray;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 20px;
  display: block;
  margin-left: 10px;

  .rtl &{
    margin-left: 0;
    margin-right: 10px;
  }
}

.preview-details-container {
  @include flexible(column, space-between, flex-start);
  flex: 1;
}

.preview-image-share-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  position: relative;
}
