@import "src/styles/colors";
@import "src/styles/typography";
@import "src/styles/mixins";

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  line-height: 12px;
  cursor: pointer;

  .text {
    @extend %font-sm;
    color: $primary-cerulean;
    margin-right: 3px;
  }

  .icon {
    width: 12px;
    height: 12px;

    path {
      fill: $red;
    }
  }

  &.see-details {
    .text {
      text-transform: uppercase;
    }

    .icon {
      transform: rotate(90deg);
      .rtl & {
        transform: rotate(-90deg);
      }

      path {
        fill: $primary-cerulean;
      }
    }
  }
}
