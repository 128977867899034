@import 'src/styles/colors';

.search-field {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;

  .search-icon-container {
    display: flex;
    position: absolute;
    height: 100%;
    top: 0;
    padding: 0 15px;
    align-items: center;
    transition: opacity .3s;

  }
  &:focus-within .search-icon-container{
    opacity: 0.5;
  }
  .close-icon-container {
    display: flex;
    position: absolute;
    padding: 0 15px;
    align-items: center;
    right: 0;

    &.rtl {
      left: 0;
      right: auto;
    }

    .close-text-icon {
      path {
        fill: $secondary-comet;
      }
    }
  }

  input {
    display: flex;
    flex: 1;
    height: 42px;
    padding: 0 45px;
    border-radius: 21px;
    border: none;
    &:focus-visible {
      outline: none;
    }
  }
}
