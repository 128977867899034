@import "src/styles/colors";
@import "src/styles/typography";

.setting-list {

  .setting-list-container {
    display: flex;
    flex-direction: column;
    padding: 28px 28px 5px 28px;
    width: 100%;
    height: 100%;
    background-color: white;

    .setting-list-item {
      display: flex;
      flex-direction: column;
      margin-bottom: 38px;

      .title {
        @extend %font-weight-bold;
        margin-bottom: 10px;
        font-size: 18px;
        color: $primary-blue;
        line-height: 21px;
      }
    }

    .updateCardContainer {
      @extend %font-weight-bold;

      display: flex;
      align-items: center;
      width: 100%;
      height: 44px;
      padding: 8px 20px;
      background-color: $background-primary;
      color: $light-gray;
      font-size: 14px;
      border-radius: 4px;
      transition: .2s all ease-in-out;

      &.isActive {
        border: 2px solid $primary-cerulean;
        border-radius: 4px;
        background-color: #E6F7FC;
        box-shadow: 0 0 4px 0 getColor(black, 0.1);
      }
    }
  }

  .dropdown-title {
    @extend %font-weight-bold;

    font-size: 18px;
    line-height: 22px;
    margin-bottom: 12px;
    color: $primary-blue;
  }
}
  .settings-preview {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: .1s all;

    &.isActive {
      background-color: white;

    }
  }
