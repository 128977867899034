@import "src/styles/colors";
@import "src/styles/typography";

.trends-line-chart-container {
  width: 100%;
  direction: ltr;

  &.summary {
    background-color: $background-primary;

    .recharts-responsive-container .trends-line-chart .recharts-surface {
      .recharts-reference-line {
        line {
          stroke-width: 1px;
          stroke-dasharray: 6 5;
        }
      }
      .recharts-tooltip-cursor {
        stroke: $orange;
      }

      .recharts-active-dot .my-stores-dot {
        outline: 2px solid $secondary-regal-blue;
        stroke: $white;
        fill: $red;
        stroke-width: 4px;
        border-radius: 100%;
      }

      .my-stores path {
        stroke: $secondary-regal-blue;
      }

    }
  }

  .recharts-responsive-container .trends-line-chart {
    .recharts-legend-wrapper {
      display: flex;
      align-items: flex-end;
      justify-content: center;
    }

    .recharts-tooltip-wrapper {
      width: 92px;
      height: 70px;
      display: flex;
      align-items: center;
      justify-content: center;

      .trends-line-tooltip {
        @extend %font-sm;
        @extend %font-weight-medium;
        padding: 8px 4px 8px 9px;
        line-height: 15px;
        display: flex;
        background-color: $white;
        flex-direction: column;
        justify-content: center;

        .rtl &{
          padding: 8px 9px 8px 4px;
          direction: rtl;

        }
        .tooltip-content {
          color: $light-orange;

          .my-stores-tooltip {
            color: $secondary-regal-blue;
          }
        }

        .chain-wide-tooltip {
          color: $light-orange;
        }
        &.hide{
          display: none;
        }
      }
    }

    .recharts-surface {
      .xAxis, .yAxis {
        line {
          stroke-width: 0;
        }

        .recharts-cartesian-axis-ticks .recharts-layer text {
          @extend %font-weight-medium;
          @extend %font-xsm;
          fill: $light-gray;
        }
      }

      .recharts-tooltip-cursor {
        stroke: $darkgreen;
        stroke-width: 2px;
      }

      .chain-wide path {
        stroke: $orange;
        stroke-width: 2px;
        stroke-dasharray: 6 3;
      }

      .recharts-active-dot .my-stores-dot {
        stroke-width: 0;
        fill: $primary-blue;
        outline: 6px solid $darkgreen;
        border-radius: 100%;
        r: 5;
      }

      .my-stores {
        &.display-dot {
          .recharts-line-dots circle {
            display: initial;
            stroke-width: 0;
            fill: $primary-blue;
            outline: 6px solid $darkgreen;
            border-radius: 100%;
            r: 5;
          }
        }

        path {
          stroke-width: 5px;
          stroke: $darkgreen;
        }
      }

      .recharts-reference-dot g circle {
        fill: $primary-blue;
        stroke-width: 6px;
        stroke: $darkgreen;

        .tooltip {
          width: 80px;
          height: 70px;
          fill: $white;

        }
      }

      .recharts-line .recharts-line-dots circle {
        display: none;
      }
    }

    .recharts-cartesian-grid g line {
      fill: $secondary-comet;
      stroke-width: 0.5px;
    }

  }
  .recharts-reference-line {
    line {
      stroke: $secondary-comet;
      stroke-width: 0.3;
      stroke-dasharray: 2 2;
    }
  }
}
