@import "src/styles/colors";
@import "src/styles/typography";

.ant-breadcrumb > span {
  font-size: 12px;
  cursor: pointer;

  &:last-child, .ant-breadcrumb > span:last-child a {
    @extend %font-weight-bold;
    color: $primary-cerulean;
  }
}