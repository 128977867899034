@import 'src/styles/colors';

.filter-search {
  padding: 28px 28px 28px 28px;
}

.filters-orders-groups {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 28px 28px 28px;
  overflow-y: scroll;

  .sub-title {
    color: $secondary-comet;
    font-size: 16px;
    text-transform: uppercase;
  }

  .filter-total {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .info {
      color: getColor($secondary-comet, 0.5)
    }
  }

  .remove-filter {
    color: getColor($secondary-comet, 0.5);
    font-size: 14px;
    border: none;
    background: transparent;
    &.active {
      color: $primary-cerulean;
    }
  }

  .sort-options, .group-options {
    margin-top: 28px;
    .sort-buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-top: 10px;
      gap: 10px;

      .sort-button {
        height: 80px;
        flex:1;
      }
    }
  }
}



