@import 'src/styles/_typography';
@import 'src/styles/colors';
@import "src/styles/mixins";

.button {
  @extend %font-weight-regular;
  border: 0;
  cursor: pointer;
  display: flex;
}

.button-side-menu {
  display: flex;
  flex-direction: row;
  height: 50px;
  align-items: center;
  z-index: 1000;

  &.hide {
    display: none;
  }
  .button-box {
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 5px;
    flex-shrink: 0;
    width: 205px;

    .rtl &{
      padding-left: 0;
      padding-right: 5px;
    }
    &.active {
      background: getColor($primary_cerulean, 0.15);
      border-radius: 5px;
    }
    .button {
      background: none;
      font-size: 16px;
      color: $white;
      text-align: initial;
      &.active {
        color: $primary_cerulean;
      }
    }
  }
  &.collapsed {
    .button-box {
      width: 50px;
      background: none;
    }
  }
  .indicator {
    height: 30px;
    margin-right:16px ;
    padding-right: 6px;
    border-start-end-radius: 5px;
    border-end-end-radius: 5px;

    .rtl &{
      margin-right: 0;
      padding-right: 0;
      margin-left: 16px;
      padding-left: 6px;
    }
    &.active {
      background: $primary_cerulean;
    }
  }
}

// No selectable element
.no-selectable {
  @include disable-selection;
}

@media screen and (height <= 680px) {
  .button-side-menu {
    & .button-box {
      & .icon-side-menu {
        width: 20px;
        height: 20px;
      }

      & .button {
        font-size: 14px;
      }
    }

    &.collapsed .button-box{
      width: 40px;
      justify-content: center;
    }
  }
}


