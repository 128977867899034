@import "src/styles/colors";
@import "src/styles/typography";

.drill-down-header-container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .drill-down-top-container {
    padding: 20px 20px 10px;
    background-color: $white;
    transition: all .2s;

    .text-button-container {
      display: flex;
      justify-content: space-between;

      .text-container {
        display: flex;
        flex-direction: column;
        color: $primary-blue;

        .title-container {
          @extend %font-weight-bold;
          display: flex;
          align-items: center;
          font-size: 16px;
          line-height: 22px;
          text-transform: uppercase;

          &.clickable {
            color: $primary_cerulean;
          }

          &.clickable .title{
            display: flex;
            align-items: center;
            cursor: pointer;
            color: $primary_cerulean;

            .cb-icon {
              transform: rotate(180deg);
              & > path {
                fill: $primary_cerulean;
              }
            }
          }
        }

        .sub-title {
          opacity: 0.6;
          font-size: 12px;
          line-height: 17px;
        }

        margin-bottom: 4px;
      }

      .sort-button {
        height: 30px;
        width: 30px;
        cursor: pointer;
        background: transparent;

        button {
          .icon-sort {
            g .fill-path {
              fill: #3F5066;
            }
          }
        }
      }
    }

    .bread-crumb-free-text-container {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .free-text-container {
        display: flex;
        width: fit-content;
        align-self: flex-end;

        .custom-checkbox .ant-checkbox-wrapper {
          span .label {
            &.checked {
              color: $white;
            }
          }

          .ant-checkbox-checked, .ant-checkbox-checked span, {
            border-color: $white;

            &:after {
              border-color: white;
            }
          }
        }

        &.hidden {
          display: none;
        }

        .ant-checkbox-wrapper {
          flex-direction: row;
          color: $white;
        }
      }

      .bread-crumb-container {
        @extend %font-weight-medium;
        flex: 1;
        overflow-x: auto;

        .filters-container {
          display: flex;
          width: 100%;
          overflow-x: scroll;
          margin-top: 12px;
          padding: 2px;
          height: 34px;
        }
      }
    }

    &.active {
      background-color: $primary-blue;

      .text-button-container {
        .text-container {
          color: $white;

          .title-container .cb-icon path {
            fill: $white;
          }
        }

        .sort-button button .icon-sort g .fill-path {
          fill: $white;
        }
      }

      .bread-crumb-container .ant-breadcrumb {
        color: $light-gray;

        span .ant-breadcrumb-separator {
          color: $light-gray;
        }
      }
    }
  }

  .headers-filter {
    max-height: 0;
    transition: .3s all;
    overflow: hidden;

    &.visible {
      transition: .6s all;
      max-height: 300px;
    }

  }
}
