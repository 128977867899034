@import 'src/styles/typography';
@import 'src/styles/colors';
@import "src/styles/mixins";

.alert-container {
  flex: 1;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 4px 0 getColor($black, 0.3);
  border-radius: 10px;
  padding: 30px;
  position: relative;

  &.comparable_stores_alert{
    width: 300px;
    height: fit-content;

    .buttons-container{
      button {
        width: 100%;
      }
    }
  }

  &.alert-drill-down-menu {
    padding: 25px;
    min-width: 300px;

    .tree-group {
      @extend %font-weight-bold;
      width: 100%;
      color: $secondary-comet;

      .tree-title {
        text-transform: uppercase;
        line-height: 1.5rem;
        width: 100%;
        text-align: start;
        font-size: 18px;

      }

      .options {
        width: 100%;
        padding-top: 5px;
        padding-bottom: 20px;

        .toggle-button-container {
          background: transparent;
          padding: 5px 0 5px 15px;
          width: 100%;
          font-size: 16px;
          text-align: start;
          color: $secondary-comet;

          &.isActive {
            color: $primary-cerulean;
          }
        }
      }

      &:last-child .options {
        padding-bottom: 0
      }
    }
  }

  &.print-menu {
    width: 350px;
    height: 350px;
  }

  .title {
    @extend %font-weight-bold;
    font-size: 16px;
  }

  .text {
    display: flex;
    @extend %font-weight-regular;
    padding: 20px 0;
    white-space: pre-wrap;
  }

  .checklist {
    .ant-checkbox + span {
      padding: 2px 0;
    }
  }

  .buttons-container {
    display: flex;
    flex: 1;
    width: 100%;
    bottom: 0;
    flex-direction: row;
    justify-content: space-between;
    left: 0;
    padding: 30px 0;

    .alert-button {
      @extend %font-weight-medium, %font-lg;
      width: 136px;
      height: 38px;
      border-radius: 4px;
      outline: none;
      border: none;
      line-height: 16px;
      margin: 0 5px;

      &:disabled {
        cursor: not-allowed;
        opacity: 0.7;
      }

      color: white;
      background-color: $primary-cerulean;

      &.cancel {
        border: 1px solid $primary-cerulean;
        background-color: white;
        color: $primary-cerulean;
      }
    }
  }
}

.radio-item {
  @extend %font-weight-bold;
  font-size: 16px;
  margin-bottom: 20px;
  line-height: 18px;
  display: flex;
  transition: ease 0.3s;
  cursor: pointer;

  &.checked {
    color: $primary-cerulean;
  }
}