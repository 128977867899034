@import "src/styles/colors";
@import "src/styles/typography";
@import "src/styles/mixins";

.contact-us-form-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  height: 100%;

  padding-top: 39px;
  padding-left: 38px;
  padding-right: 38px;
  background-color: white;

  .form_title {
    @extend %font-color-primary, %font-weight-bold;

    text-align: left;
    width: 100%;
    font-size: 18px;

    .rtl &{
      text-align: right;
    }
  }

  .form_text {
    color: $secondary-comet;
    text-align: left;
    width: 100%;
    margin-top: 11px;
    line-height: 20px;
    font-size: 16px;

    .rtl &{
      text-align: right;
    }
  }
}

.content .page .page-container {
  .page-list + .contact-us-image-container {
    @include flexible(column, center, center);

    background-color: transparent;
  }
}

.contact-us-image {
  width: 360px;
  height: 320px;
  object-fit: contain;
}

.contact-us-form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  width: 100%;

  .submit-button {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .contact-us-message-container {
    display: flex;
    padding-top: 10px;
    width: 100%;

    .contact-us-message {
      color: $success;
      font-size: 18px;
      &.error {
        color: $error;
      }
    }
  }
}

.contact-us-input {
  flex: 1;
  border: none;
  outline: none;
  background-color: #F3F4F5;
  border-radius: 4px;
  padding-top: 15px;
  margin-top: 8px;
  font-size: 12px;
  padding-left: 18px;

  .rtl &{
    padding-left: 0;
    padding-right: 18px;
  }
  &::placeholder {
    color: #A8ADB6;
  }
}

.contact-us-input-container {
  @include flexible(row, flex-start, flex-start);

  flex-wrap: wrap;
  width: 100%;
  margin-top: 39px;

  .title {
    @extend %font-weight-bold;

    width: 100%;
    font-size: 18px;
    color: $primary-blue;
  }

  .input {
    @extend .contact-us-input;

    padding-bottom: 12px;
  }

  .textarea {
    @extend .contact-us-input;

    resize: none;
    height: 180px;
  }
}

.submit-contact-us-form {
  color: white;
  font-size: 16px;
  line-height: 16px;
  background-color: $primary-cerulean;
  border-radius: 4px;
  align-self: flex-end;
  outline: none;
  border: none;
  transition: 0.2s all;
  padding: 11px 56px;
  margin-top: 13px;

  &:disabled {
    opacity: 0.5;
  }
}
