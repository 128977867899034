@import 'src/styles/_colors.scss';
@import 'src/styles/typography.scss';

html,
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  padding: 0;
  background: $keycloak-blue;
  height: 100%;
  overflow: hidden;
}
code, textarea, input, root {
  @extend %font-family;
}

/* Root div */
#root {
  background: $background-primary;
  height: 100%;
  overflow: hidden;
  overflow-x: auto;

  &::-webkit-scrollbar:horizontal {
    display: none;
    height: 0;
    scrollbar-height: 0;
    -ms-overflow-style: none;
    white-space: nowrap;
  }
}

div {
  box-sizing: border-box;
}

button:not([disabled]) {
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 4px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  width: 4px;
  background-color: getColor($light-gray, 0.3);
  border-radius: 2px;
}

.rtl {
  direction: rtl;
}
//@media screen and (max-width: 1500px) {
//  html body {
//    transform-origin: top left ;
//    transform: scale(0.7);
//    // calculate new height/width corresponding to the scale:
//    width: calc(100%/0.7);
//    height: calc(100%/0.7);
//    will-change: transform;
//  }
//}
