@import "src/styles/mixins";
@import "src/styles/colors";
@import "src/styles/typography";

.small-tabs {
  font-size: 10px;
  color: #4F5B70;
}

.medium-tabs {
  text-transform: uppercase;
  font-size: 12px;
  color: #4F5B70;
}

.activeTab, .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #515B6E;
}

.ant-tabs-ink-bar {
  border-bottom: 2px solid $primary-cerulean;
}


.ant-tabs, .ant-tabs > .ant-tabs-nav, .ant-tabs > div > .ant-tabs-nav, .ant-tabs-tab {
  height: fit-content;
  margin: 0;

}


.ant-tabs-top > .ant-tabs-nav::before, .ant-tabs-bottom > .ant-tabs-nav::before, .ant-tabs-top > div > .ant-tabs-nav::before, .ant-tabs-bottom > div > .ant-tabs-nav::before {
  border-bottom: none;
}

.ant-tabs-tab {
  padding: 1px 0;
}

.tab {
  text-transform: uppercase;
  .amount {
    font-size: 12px;
    color: $white;
    padding: 0 6px;
    background: $primary-cerulean;
    margin: 0 0 0 5px;
  }

  .amount.rtl {
    margin: 0 5px 0 0;
  }
}
