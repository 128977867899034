@import "src/styles/colors";
@import "src/styles/typography";
@import "src/styles/mixins";

.drill-down-item {
  position: relative;
  width: 100%;
  height: 65px;

  .drill-down-container {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 12px 20px;
    height: 65px;
    background-color: $white;
    cursor: pointer;
    border-top: 1px solid getColor($offwhite, 0.5);
    justify-content: space-between;

    &.is-options-bar-active {
      pointer-events: none;
      cursor: unset;
    }

    .drill-down-count-container {
      @extend %font-weight-medium;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      width: 70px;

      .value-container {
        color: $secondary-regal-blue;
        font-size: 18px;
        line-height: 25px;
      }
    }

    .name-line-container {
      @extend %font-weight-medium;

      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1;
      line-height: 17px;
      padding: 0 18px 0 24px;
      font-size: 12px;
      color: $secondary-comet;
      overflow: hidden;

      .line {
        background-color: $secondary-regal-blue;
        height: 4px;
        max-width: 100%;
        border-radius: 4px;
      }

      .name{
        @include ellipse-text;
      }
    }
  }
}