@import "src/styles/colors";
@import "src/styles/typography";

.custom-checkbox {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex: 1;
  padding: 0 2px;

  span:nth-child(2) {
    display: flex;
    flex: 1;
    flex-direction: row;
  }

  .label {
    display: flex;
    transition: ease 0.3s;
    @extend %font-weight-regular;

    &.checked {
      color: $primary-cerulean;
    }
  }

  .count {
    display: flex;
    flex: 1;
    transition: ease 0.3s;
    padding: 0 5px;
    @extend %font-weight-regular;
    color: getColor($black, 0.5);

    &.checked {
      color: $primary-cerulean;
    }
  }

  .ant-checkbox-wrapper {
    display: flex;
    flex: 1;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    height: 100%;

    .ant-checkbox {
      border-color: $primary-disable;
      top: 0;

      .ant-checkbox-input {
        border-color: $primary-disable;
      }

      &.ant-checkbox-checked {
        border-color: $primary-cerulean;
      }
    }
  }

  .ant-checkbox-inner {
    border-color: $primary-disable;
    background-color: transparent;

    &::after {
      width: 4px;
      height: 8px;
      top: 42%;
    }
  }

  // Selected
  .ant-checkbox-checked {
    border-color: $primary-cerulean;

    .ant-checkbox-inner {
      background-color: transparent;
      border-color: $primary-cerulean;

      &::after {
        border-color: $primary-cerulean
      }
    }
  }

  // Focus and Hover
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus-visible,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: $primary-disable;
  }
}

.custom-checkbox:hover {
  background: getColor($secondary-slate-gray, 0.1);
}
