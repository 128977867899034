@import "src/styles/colors";
@import "src/styles/mixins";
@import "src/styles/typography";

// wraps the card and space from next card instead of margin which cause it to flicker
.card-wrapper {
  display: flex;
  height: 92px;
  padding: 3px 0;

  &.completed-with-reason{
    height: 132px;
  }

  &.card-with-impact-on-sales-chart{
    height: 240px;
  }

  .card-container, .card-container-empty {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: white;
    box-shadow: 0 0 4px 0 getColor(black,0.1);
    border-radius: 4px;
    cursor: pointer;
    border: 2px solid transparent;
    transition: .2s all ease-in-out;

    &.active-card {
      border: 2px solid $primary-cerulean;
      border-radius: 4px;
      background-color: #E6F7FC;
      box-shadow: 0 0 4px 0 getColor($black, 0.1);
      pointer-events: none;
    }

    .card-header {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      flex: 1;
      padding: 8px;

      .card-header-text-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-left: 7px;

        .rtl &{
          margin-left: 0;
          margin-right: 7px;
        }

        .title-container {
          display: flex;
          align-items: center;

          .card-title {
            display: flex;
            font-size: 12px;
            @include ellipse-text;
            font-weight: 500;
            text-align: left;
            height: 17px;

            .rtl &{
              text-align: right;
            }
          }

          .expired-task {
            display: inline-flex;
            border-radius: 2px;
            margin: 0 8px;
            background-color: getColor(#df462e, 0.15);
            text-align: center;

            span {
              @extend %font-weight-medium;
              color: getColor($red, 0.8);
              font-size: 10px;
              line-height: 14px;
              padding: 0 4px;


            }
          }
        }

        .card-id {
          display: flex;
          font-size: 12px;
          font-weight: 500;
          color: #A8ADB6;
          text-align: left;
          line-height: 16px;

          .rtl &{
            text-align: right;
          }
          .card-id-text:before {
            white-space: pre;
            content: " ";
          }
        }
      }

    }

    .card-footer {
      display: flex;
      flex-direction: row;
      height: 27px;

      .card-footer-text {
        font-size: 12px;
        font-weight: 500;
        color: #67808E;
        @include ellipse-text;
        display: block;
        max-width: 10em;
      }

      .card-footer-store {
        display: flex;
        flex: 4;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        .card-footer-text {
          max-width: 160px;
        }
      }

      .card-footer-sales {
        display: flex;
        flex: 3;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
      }

      .card-footer-category {
        display: flex;
        flex: 3;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: 0 8px;
      }

    }

  }

  &.no-issue-found-or-expired .card-container .card-footer .card-footer-store .cb-icon path {
    fill: #c8c8c8;

  }

  &.ia .card-container .card-footer .card-footer-store {
    .card-footer-text {
      color: $secondary-summer-green;
    }

    .cb-icon path {
      fill: $secondary-summer-green;
    }
  }
}

.line-empty-card {
  width: 100px;
  height: 0.35rem;
  background-color: #DDD;
  border-radius: 5px;
}


.card-title-empty {
  width: 120px;
  height: 0.4rem;
  display: inline-block;
  background-color: #DDD;
  border-radius: 5px;
}


.card-footer-empty {
  @include flexible(row, space-between, center);
  width: 460px;
  height: 20px;
  margin: 0 12px;
}



.card-text-empty {
  width: 70px;
  height: 0.4rem;
  display: inline-block;
  background-color: #DDD;
  border-radius: 5px;
  margin: 12px 1px 0;
}

.card-container-empty {
  animation: fading 1.6s infinite;
}

@keyframes fading {
  0% {
    opacity: 1;
  }

  50% {
    opacity: .6;
  }

  100% {
    opacity: 1;
  }
}
