@import "src/styles/mixins";
@import "src/styles/colors";

.year-line-chart {
  stroke: $secondary-comet;
  stroke-width: 0.3;

  .circle-text {
    border-radius: 100%;
    border: 1px dashed $secondary-comet;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      text-align: center;
      color: $light-gray;
      font-size: 10px;
      line-height: 22px;
    }
  }
}
