@import "src/styles/colors";
@import "src/styles/typography";
@import "src/styles/mixins";

.leader-board-item-wrapper {
  color: $secondary-regal-blue;
  padding: 0 26px;

  &.selected {
    background-color: $secondary-regal-blue;
    color: $white;

    .leader-board-item-container {
      border-bottom: 0.5px $secondary-regal-blue solid;

      .leader-board-item .issue-found {
        color: $light-gray;
      }
    }
  }

  &:last-child .leader-board-item-container {
    border-bottom: 0.5px $white solid;
  }

  .leader-board-item-container {
    padding: 11px 26px;
    border-bottom: 0.5px $gray solid;

    .leader-board-item {
      display: flex;
      flex-direction: row;
      line-height: 27px;
      align-items: center;

      .leader-name {
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .leader-value, .issue-found {
        display: flex;
        justify-content: space-around;
      }

      .rank-number {
        flex: 1;
        @extend %font-xl;
        @extend %font-weight-medium;

        .numero-symbol {
          @extend %font-md;
        }
      }

      .leader-name {
        flex: 2;
        @extend %font-sm;
        text-transform: capitalize;
        @extend %font-weight-medium;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .leader-value {
        @extend %font-weight-medium;
        flex: 1;
      }

      .issue-found {
        flex: 1;
        color: $secondary-comet;
      }

      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
