@import "src/styles/typography";
@import "src/styles/colors";


.inventory-popup-container {
  width: 500px;
  height: 400px;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;

  .popup-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    padding-bottom: 15px;

    .header-description {
      font-weight: 500;
      padding-bottom: 5px;
    }
  }

  .popup-content {
    display: flex;
    justify-content: center;
    overflow-y: auto;

    .live-inventory-table {
      margin: 0 20px;
      width: 100%;
      text-transform: uppercase;

      .table-header-col,
      .table-row-col{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .table-header-sub-text,
        .table-row-sub-text{
          margin-bottom: -4px;
        }
      }

      th,td {
        border:2px solid $white;
        height: 36px;
      }

      th {
        background: $secondary-summer-green;
        color:$white;
      }

      td {
        padding: 0 8px;
        background: $gray;
      }
    }
  }
}