@import "src/styles/colors";
@import "src/styles/mixins";
@import "src/styles/typography";

.modal-container {
  &.print-mode {
    position: initial;
    display: none;

    .content {
      width: 100%;
      height: 100%;
    }
  }
}

.card-print-page-table {
  width: 100%;

  thead, thead tr, thead tr td {
    width: 100%;
    padding: 0;

  }

  tbody {
    .reasons-print-container {
      border-bottom: 1px solid $secondary-comet;
      padding: 10px;
      height: 100%;


      .reasons-print-title {
        @extend %font-weight-bold;
        color: $black;
        font-size: 14px;
        line-height: 14px;
      }

      .reasons-print-group {
        display: flex;
        flex-wrap: wrap;

        .reasons-print-list {
          display: flex;
          flex-direction: column;
          font-size: 10px;
          flex-basis: 30%;

          .reason-list-title {
            font-size: 12px;
            margin-top: 4px;
            color: $secondary-comet;
            text-decoration: underline;
          }

          .reasons-print-options {
            margin-right: 20px;

            .rtl & {
              margin-right: 0;
              margin-left: 20px;
            }

            .reason-print-row {
              display: flex;
              margin-top: 4px;
              font-size: 11px;

              .reason-number-print {
                font-size: 14px;
                line-height: 16px;
                color: $primary-blue;
              }
            }
          }
        }
      }
    }

    tr td .group-header {
      @extend %font-weight-bold;
      font-size: 14px;
      margin: 6px 10px 0 10px;
      padding: 4px;
    }
  }

  thead {
    display: flex;

    tr {
      display: flex;

      td {
        display: flex;

        .header-text {
          flex: 1;
          display: flex;
          flex-direction: column;
          font-size: 16px;
          color: $black;
          line-height: 24px;
          padding: 10px;
          width: 100%;
          border-bottom: 1px solid $secondary-comet;

          .filter {
            color: $secondary-comet;
            margin-top: 10px;
          }

          .header-date {
            color: $secondary-comet;
          }
        }
      }
    }
  }
}

@media print {
  @page {
    margin: 10px;
    size: portrait;
  }

  body {
    overflow: initial;
  }

  #root {
    display: none;
  }

  .modal-container {
    &.print-mode {
      display: initial;
    }
  }

  table {
    tr {
      page-break-inside: avoid;

      td {
        padding: 0;
      }
    }
  }
}
