@import "src/styles/colors";
@import "src/styles/mixins";
@import "src/styles/typography";

.findings-chart-container {
  width: 100%;

  .findings-empty-state-text {
    @extend %font-md;
    fill: $white;
  }

  .recharts-responsive-container .recharts-wrapper {
    .recharts-legend-wrapper {
      display: flex;
      align-items: center;

      .findings-legend-container {
        @include ellipse-text;
        display: flex;
        position: absolute;
        flex-direction: column;
        padding: 20px;
        flex-wrap: wrap;
        height: 140px;
        top:0;

        .legend-row {
          display: flex;
          align-items: center;
          margin-bottom: 16px;
          color: white;
          cursor: pointer;
          font-size: 16px;
          text-transform: capitalize;
          opacity: 0.3;
          width: 200px;

          span {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }

          &.active {
            opacity: 1;
          }

          &.invisible {
            display: none;
          }

          .findings-line-legend {
            border-radius: 1px;
            margin-right: 12px;
            width: 6px;
            height: 30px;

            .rtl & {
              margin-left: 12px;
              margin-right: 0;
            }
          }
        }
      }
    }

    .recharts-surface .recharts-pie {
      .recharts-pie-labels {
        @extend %font-lg;
      }

      .recharts-pie-sector {
        cursor: pointer;

        path {
          stroke: none;

          &.active {
            filter: drop-shadow(0 0 3px getColor($black, 0.5));
            stroke: white;
            stroke-width: 1px;
          }
        }
      }
    }
  }

  &.small {
    .recharts-responsive-container .recharts-wrapper {
      .recharts-surface .recharts-pie .recharts-pie-labels{
        @extend %font-md;

      }
      .recharts-legend-wrapper .findings-legend-container .legend-row {
        @extend %font-sm;
        margin-bottom: 11px;

        .findings-line-legend {
          height: 20px;
          width: 4px;
        }
      }
    }
  }

  .findings-tooltip {
    border: none;
    background-color: getColor($white, 0.9);
    border-radius: 3px;
    padding: 4px 8px;
    text-transform: capitalize;
  }
}

// summary findings chart legend container
.summary-findings-chart .recharts-responsive-container .recharts-wrapper

.recharts-legend-wrapper {
  .findings-legend-container {
    bottom: 0;
    top:auto;
    padding: 0;
    flex-wrap: nowrap;
  }
}

