@import "src/styles/colors";
@import "src/styles/typography";

.impact-on-sales-chart {
  width: 100%;
  height: 110px;
  display: flex;
  align-items: flex-start;
  justify-content: center;

}
.grid-impact-on-sales{
  stroke-width: 1px;
  stroke: #E7E7E7;
}
.line-impact-on-sales {
  path {
    stroke: #2E9C78;
    stroke-width: 2px;
  }
  g{
    circle{
      stroke: #2E9C78;
      stroke-width: 2px;
    }
  }
  &.no-found-or-expired{
    path {
      stroke: #67808D99;
    }
    g{
      circle{
        stroke: #67808D99;
      }
    }
  }

}

.tooltip {
  display: flex;
  flex-direction: column;
  background-color: getColor(white, 0.85);
  border-radius: 4px;
  border: 1px solid #102540;
  padding: 4px;

  span {
    @extend %font-weight-regular;
    font-size: 10px;
    line-height: 10px;
    padding: 2px;
  }
}

.respond-line-chart {
  line {
    stroke: #0BB5E2;
    stroke-width: 1px;
  }

  rect {
    fill: #0bb5e24c;
  }

  .line-text {
    font-size: 10px;
    line-height: 14px;
    text-align: center;
  }

  &.no-found-or-expired {
    line {
      stroke: #dfe2e5;
      stroke-width: 1px;
    }

    rect {
      fill: #dfe2e5;
    }

    .line-text {
      color: $primary-blue;
    }
  }
}