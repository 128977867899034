@import "src/styles/mixins";
@import "src/styles/typography";
@import "src/styles/colors";

.reasons-list-accordion {
  @include flexible(column, flex-start, flex-start);
  padding: 0 28px;
  background-color: $secondary-geyser;

  .complete-received-date {
    display: none;
  }

  &:last-child {
    .complete-received-date {
      @extend %font-md;
      @extend %font-weight-medium;
      width: 100%;
      border-top: 1px solid getColor($secondary-comet, 0.6);
      display: flex;
      flex-direction: column;
      color: getColor($secondary-comet, 0.8);
      padding: 12px 0;

      .complete-date {
        display: none;

        &.visible {
          display: initial;
        }
      }
    }
  }
}

.reasons-list-accordion-header {
  @extend %font-weight-bold, %font-lg;
  color: $secondary-comet;
  width: 100%;
  text-align: left;
  padding: 23px 0 10px 0;
  height: 60px;

  .rtl & {
    text-align: right;
  }
}
