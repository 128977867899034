@import "src/styles/colors";

.popup-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 25px;
  position: relative;

  .popup-close-button {
    height: 25px;
    width: 25px;
    display: block;
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: transparent;
    cursor: pointer;

    .rtl & {
      left: 6px;
    }

    .icon-close {

      path {
        fill: getColor($secondary-comet, 0.5);
      }
    }

    &:hover {
      .icon-close {
        path {
          fill: getColor($secondary-comet, 0.8);
        }
      }
    }
}

.popup-header {
width: 100%;
}

.popup-content {
width: 100%;
}
}
