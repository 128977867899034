@import "src/styles/colors";
@import "src/styles/typography";

#summary {
  overflow-y: auto;
}

.summary {

  .page-header .dropdown-container {
    text-transform: uppercase;
    min-width: 300px;

    .search-input {
      text-transform: uppercase;
    }

    .dropdown-row,
    .dropdown-list .dropdown-row {
      @extend %font-weight-medium;
      @extend %font-sm;
      padding-bottom: 0;
    }

    .c-radio-item {
      display: none;
    }
  }


  .page-header {
    gap: 22px;
    .summary-filter-by-store {
      .dropdown-list .dropdown-row {
        padding:9px 24px;
      }
    }
  }

  .top-findings-wrapper, .rate-comparison-container {
    border-radius: 4px;
    box-shadow: 0 0 3px 0 getColor($black, 0.1);
  }

  .page-preview-bottom, .page-list-bottom {
    padding-top: 50px;
  }

  #summary-container {
    flex: unset;
    min-height: 520px;
    overflow: hidden;
  }

  .page-container {
    .weekly-comparison-cards, .top-findings-wrapper, .kpi-cards {
      display: flex;
      flex-direction: column;
      height: 454px;
    }

    .weekly-comparison-cards #hit-rate {
      margin-bottom: 0;
    }

    .weekly-comparison-cards {
      margin-right: 16px;

      .rtl & {
        margin-right: 0;
        margin-left: 16px;
      }
    }

    .page-preview {
      display: flex;
      flex-direction: column;

      .page-preview-top {
        display: flex;
        flex-direction: row;

          .weekly-comparison {
            flex: 2
          }

          .top-findings {
            flex: 3;

            .top-findings-wrapper {
              background-color: $primary-blue;
              display: flex;
              flex-direction: column;

              .top-findings-chart {
                padding: 14px 24px;
                display: flex;
                flex-direction: column;
                flex: 1;
                position: relative;

                .chart-total {
                  position: absolute;
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                }

                .header {
                  letter-spacing: 1px;
                  line-height: 19px;
                  @extend %font-md;
                  @extend %font-weight-medium;
                  color: $white;
                  text-transform: uppercase;
                }

                .total-number {
                  @extend %font-2xl;
                  @extend %font-weight-medium;
                  letter-spacing: 0.5px;
                  color: $white;
                  line-height: 40px;
                  margin: 0;
                }
              }

              .top-reason-cards {
                display: flex;
                flex-direction: column;
                color: $white;
                height: 150px;
                @extend %font-weight-medium;
                border-top: 1px solid $gray;

                .header {
                  display: flex;
                  flex-direction: row;
                  height: 24px;
                  justify-content: space-between;
                  align-items: center;
                  width: 100%;
                  text-transform: uppercase;
                  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
                  background-color: #273450;
                  padding: 0 24px;

                  .title {
                    line-height: 17px;
                    @extend %font-sm;
                    color: $white;
                    text-wrap: none;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }

                  .see-details {
                    text-align: right;
                  }
                }

                .reason-cards {
                  display: flex;
                  flex-direction: row;
                  padding: 0 24px;
                  height: 110px;
                  position: relative;

                }
              }
            }
          }
        }
    }

    .page-list {
      display: flex;
      flex-direction: column;

      .decorative-footer {
        flex: 1;
      }

      .summary-bottom {
        display: flex;
        flex-direction: row;
      }
    }
  }

  .page-footer {
    display: flex;
    flex: 1;
    flex-direction: row;

    .page-preview-bottom {
      display: flex;
      flex: 1;

      .rate-comparison-wrapper {
        display: flex;
        flex-direction: column;
        flex: 1;

        .column-header {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 0;
          margin-top: 0;
          letter-spacing: -0.15px;

          .title-container .time-span {
            color: $secondary-comet;
            @extend %font-md;
            @extend %font-regular;
            padding: 0 20px;
            text-transform: capitalize;
          }

          .tabs-container {
            margin: 0 30px;

            .ant-tabs-tab {
              height: 35px;
              width: 178px;
              display: flex;
              justify-content: center;
              border-top-left-radius: 4px;
              border-top-right-radius: 4px;
              border-bottom: none;
              background-color: $offwhite;
              color: $light-gray-check;
              letter-spacing: -0.4px;
              margin-left: 5px;

              &.ant-tabs-tab-active {
                background-color: $white;
                color: $primary-blue;

                svg path {
                  fill: $primary-blue;
                }
              }

              div[role='tab'] {
                display: flex;
                align-items: center;

                svg[role='img'] {
                  margin-left: 5px;

                  .rtl & {
                    margin-right: 5px;
                  }
                }
              }
            }
          }
        }

        .rate-comparison-container {
          display: flex;
          flex: 1;
          flex-direction: row;
          padding: 6px;
          background-color: $white;
          z-index: 1;
          position: relative;

          .empty-state-container {
            position: relative;
            margin: auto;
            color: $light-gray-check;
            background-color: $offwhite;
            @extend %font-lg;
            padding: 15px 0;
          }
        }
      }
    }

    .page-list-bottom {
      display: flex;
      height: 100%;
      width: 534px;
      padding-left: 16px;

      .hit-rate-leaders {
        .column-header {
          margin-top: 0;
        }

        display: flex;
        flex-direction: column;
        flex: 1;
      }
    }
  }

  .page-footer-decorator {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 10px 0;
    justify-content: flex-end;
    align-items: flex-end;

    .powered-by {
      display: flex;
      align-items: center;

      .message {
        @extend %font-md;
        padding-left: 10px;
      }
    }
  }
}

.App .content .summary .page-container {
  .page-list {
    padding-right: 0;
    padding-left: 16px;
    padding-bottom: 0;

    .rtl & {
      padding-right: 16px;
      padding-left: 0;
    }
  }

  .page-preview {
    padding-bottom: 0;
  }
}

.column-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: $primary-blue;
  @extend %font-lg;
  @extend %font-weight-medium;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  margin-top: 20px;
  margin-bottom: 15px;

  .date-select-container{
    z-index: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 5px;

    .date-select-icon {
      path {
        fill: $primary-cerulean;
      }
    }
  }

  .dropdown-container {
    text-transform: capitalize;
    padding: 3px;
    text-wrap: none;

    .select-bar {
      padding: 0;
    }

    .dropdown-row {
      height: 20px;

      span {
        width: 90px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        letter-spacing: 0;
      }
    }

    .dropdown-list {
      overflow: hidden;
      margin-top: 24px;
      background-color: $white;
      box-shadow: 0 0 3px 0 getColor($black, 0.1);

      .dropdown-row {
        height: 30px;
        line-height: 30px;
        padding: 0 0 0 24px;

        .rtl & {
          padding: 0 24px 0 0;
        }

        span {
          width: 110px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        &.isChecked {
          @extend %font-weight-medium;
        }

        label {
          display: none;
        }
      }
    }
  }

  &.leader-board {
    justify-content: space-between;
    justify-items: center;
    margin-bottom: 0;

    .header-title-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      z-index: 100;
      letter-spacing: -0.27px;
    }
  }
}
