
.reasons-list-container {
  width: 100%;
  flex: 1;
  overflow: auto;
  scroll-behavior: smooth;
}

.reasons-list-container > :last-child {
  height: 100%;

}
