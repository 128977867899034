@import "src/styles/colors";
@import "src/styles/typography";

.drill-down {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }

  .drill-down-inner {
    border: 1px solid getColor($black, 0.1);
    display: flex;
    flex-direction: column;
    border-bottom: 0;
    border-radius: 2px;
    overflow-y: hidden;

    .expended & {
      border-bottom: 1px solid getColor($black, 0.1);
      flex: 1;
    }

    .cards-container .virtuoso-group-list {
      max-height: 100%;
    }
  }

  &.hidden {
    display: none;
  }

  &.expended {
    flex: 1;
    overflow-y: hidden;
    margin-bottom: 0;
    background: $background-primary;
  }
}

.ant-breadcrumb li:last-child,
.ant-breadcrumb li:last-child a,
.ant-breadcrumb li{
  color: $primary-cerulean;
}

.ant-breadcrumb-separator {
  color: $light-gray;
}