@import "src/styles/typography";
@import "src/styles/colors";

.my-opportunities-list-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  .list-control-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .counter-tabs-container {
      display: flex;
      align-items: center;
    }

    .deep-link-filter {
      display: flex;
      align-items: center;
      flex: 1;
      justify-content: flex-start;
      padding: 0 10px;
    }

    .list-controls {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 9px 0;
      gap: 4px;

      .filter {
        display: flex;
        cursor: pointer;
      }

      .print {
        display: flex;
        cursor: pointer;

        &.invisible{
          display: none;
        }

      }
    }
  }
}

.product-sale {
  display: flex;
  flex-direction: column;
  flex: 1;

  .product-sale-data {
    padding: 20px 27px;

    .product-sale-data-header {
      display: flex;
      align-items: center;
    }

    .opportunity-toggle {
      background: $antBlue;
    }

    .title {
      font-size: 18px;
      flex:0.6;
      color: $primary-blue;
    }

    .chart-info {
      display: flex;
      flex-direction: row;
      margin: 10px 0;

      .chart {
        display: flex;
        flex-direction: column;
        padding: 20px;
        border: 1px solid $secondary-geyser;
        box-shadow: getColor($black, 0.08) 0 3px 10px 0;
        height: max-content;
      }

      .sub-title {
        font-size: 12px;
        color: $primary-blue;
        text-transform: uppercase;
        padding: 10px 20px;
      }

      .info {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0 20px;
        max-height: 400px;
        overflow-y: auto;
        flex:1;
        gap:10px;
      }
    }
  }
}

.preview-data {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
}

.opportunities-tabs {
  padding: 26px 0;
}

.my-opportunities-search-container {
  margin: 0 22px;
  width: 860px;

  input {
    color: $primary-blue;
    font-size: 12px;
    width: 860px;
    height: 42px;

    ::placeholder {
      color: $primary-disable;
    }
  }
}

.story-animation-container {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;

  .storybook-item-container.animations {
    height: 100vh;
    transform: scale(0.8);
  }
}


