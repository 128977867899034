@import "src/styles/colors";
@import "src/styles/typography";
@import "src/styles/mixins";

.drill-down-opportunity-card {
  width: 100%;
  background-color: $white;
  display: flex;
  flex-direction: column;
  border: 1px solid $gray;
  height: 115px;

  .drill-down-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 10px 12px;
    font-size: 12px;
    cursor: pointer;

    .header {
      display: flex;
      justify-content: space-between;
      line-height: 17px;

      .expired {
        align-items: center;
        border-radius: 2px;
        background-color: getColor($red, 0.15);
        text-align: center;
        @extend %font-weight-medium;
        color: getColor($red, 0.8);
        font-size: 10px;
        line-height: 11px;
        padding: 0 4px;
        display: none;

        &.visible {
          display: inline-flex;

        }
      }

      .title {
        @extend %font-weight-medium;
        color: $primary-blue;
        padding-left: 5px;

        .rtl & {
          padding-left: 0;
          padding-right: 5px;
        }

      }
    }

    .card-id {
      @extend %font-weight-medium;
      display: flex;
      justify-content: flex-start;
      width: 100%;
      color: $light-gray;
      padding: 0 5px;
      margin-top: 2px;
      line-height: 17px;
    }

    .drill-down-content-footer {
      display: flex;
      justify-content: space-between;

      .card-footer-category {
        display: flex;
        align-items: center;
        margin-top: 2px;

        .card-footer-text {
          line-height: 12px;
          font-size: 12px;
          color: $secondary-slate-gray;

          &.store-text {
            color: $red;
          }
        }
      }
    }
  }
}


.reason-code-footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: $background-primary;
  color: $secondary-comet;
  font-size: 12px;
  padding: 6px 17px;
  line-height: 17px;

  .reason {
    font-size: 12px;
    @extend %font-weight-bold;
  }

  .description {
    @include ellipse-text;
    font-size: 10px;

  }
}