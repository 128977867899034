@import "src/styles/colors";
@import "src/styles/typography";

.toggle-button-container {
  @extend %font-weight-medium;

  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: $background-primary;
  padding: 12px 18px;
  font-size: 14px;
  line-height: 17px;
  color: $light-gray;
  border-radius: 4px;
  cursor: pointer;
  transition: .2s all;

  svg {
    transition: .2s opacity;
    opacity: 0;
  }

  &.isActive {
    @extend %font-weight-bold;
    color: $primary-cerulean;

    svg {
      opacity: 1;
    }

  }
}
