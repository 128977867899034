@import "src/styles/_mixins.scss";

.video-preview {
  @include flexible(column, flex-start);

  overflow: auto;
  width: 100%;
  height: 100%;
  background-color: white;
}
