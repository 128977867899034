@import "src/styles/colors";
@import "src/styles/typography";

.preview-card-container {
  background-color: $white;
  padding: 0 26px;
  display: flex;
  box-shadow: 0 0 4px 0 getColor($black, 0.1);
  align-items: center;
  gap:20px;
  cursor: pointer;
  height: 62px;
  flex: auto; // same as flex: 1 1 auto

  .values-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60px;

    &.loading {
      visibility: hidden;
    }

    .value {
      @extend %font-xl;
      color:$primary-blue;
      line-height: 1.3;
    }
  }

  .title-line-wrapper {
    display: flex;
    flex-direction: column;
    flex:1;
    overflow: hidden;

    .title {
      color:$secondary-comet;
      @extend %font-md;
      @extend %font-weight-medium;
    }
  }

  &.red {
    .open-opportunities-progress-line { background-color: $red }
    .value { color: $red }
  }

  &.yellow {
    .open-opportunities-progress-line { background-color: $yellow }
    .value { color: $yellow }
  }

  &.green {
    .open-opportunities-progress-line { background-color: $secondary-summer-green }
    .value { color: $secondary-summer-green }
  }

  &.gray {
    .open-opportunities-progress-line { background-color: $store-gray }
    .value { color: $store-gray }
  }

  &.rtl {
    .cb-icon { transform: rotate(-180deg) }
  }
}