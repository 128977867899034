@import "src/styles/colors";
@import "src/styles/typography";

.filters-search-container {
  width: 100%;
  padding: 10px 12px;
  background-color: $white;

  .search-field input {
    border: 1px solid $gray;
  }

  .filters-container {
    display: flex;
    width: 100%;
    overflow-x: scroll;
    margin-top: 12px;
    padding: 2px;
    height: 34px;

    .no-filters-text {
      color: getColor($primary-blue, 0.6);
    }

    .filter-container {
      margin-right: 8px;
      display: flex;
      align-items: center;
      border-radius: 14px;
      padding: 2px 12px;
      background-color: getColor($light-gray, 0.3);
      color: $primary-blue;
      text-transform: capitalize;
      white-space: nowrap;
      height: 24px;

      .rtl & {
        margin-right: 0;
        margin-left: 8px;

      }

      span {
        font-size: 12px;
        line-height: 16px;
      }

      .icon-close {
        margin-left: 4px;

        .rtl & {
          margin-left: 0;
          margin-right: 4px;
        }

        height: 16px;
        width: 16px;
        cursor: pointer;

        path {
          fill: $primary-blue;
        }
      }
    }
  }
}
