@import "src/styles/colors";
@import "src/styles/mixins";

.video-card-wrapper {
  display: flex;
  height: 164px;

  .video-card-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 160px;
    background-color: white;
    box-shadow: 0 0 4px 0 getColor($black, 0.07);
    border-radius: 4px;
    border: 1px solid getColor($black, 0.1);

    .video-poster-container {
      width: 224px;
      height: 100%;

      .c-video-duration {
        right: 12px;
        bottom: 12px;
        font-size: 12px;
        line-height: 14px;
        padding: 6px;
      }

      .video-poster {
        object-fit: cover;
        border-radius: 4px 0 0 4px;
      }

      .icon-play {
        width: 53px;
        height: 53px;
      }
    }

    .video-details {
      padding: 12px 13px;
      flex: 1;
      height: 100%;

      .video-details-header {
        .video-details-title {
          margin-top: 2px;
          font-size: 16px;
          line-height: 18px;
        }

        .video-details-date {
          font-size: 14px;
          line-height: 18px;
        }
      }

      .video-details-info {
        margin-top: 5px;
        font-size: 14px;
        overflow: hidden;
        display: -webkit-box;
        height: 40px;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        line-height: 20px;
      }
    }
  }
}
