@import "src/styles/colors";
@import "src/styles/typography";
@import "src/styles/mixins";

.leader-board-empty-state {
  border-radius: 4px;
  box-shadow: 0 0 3px 0 getColor($black, 0.1);
  overflow-y: scroll;
  height: 282px;
  width: 100%;
  background: $white;
  margin-top: 6px;
  position: relative;
}

.leader-board-container {
  border-radius: 4px;
  box-shadow: 0 0 3px 0 getColor($black, 0.1);
  width: 100%;
  background: $white;
  margin-top: 6px;
  position: relative;
  overflow: auto;
  height: 100%;

  .leader-board-items-container {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 4px;
    box-shadow: 0 0 3px 0 getColor($black, 0.1);
    background-color: $white;
  }

  .three-dots-container {
    margin: 0 47%;
    position: relative;
    height: 15px;

    .icon-more {
      transform: rotate(90deg);
      position: absolute;
      top: -11px;
    }
  }
}

.leader-board-empty-state {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  @extend %font-lg-2;
  color: $secondary-comet;
}
