@import "src/styles/mixins";
@import "src/styles/typography";
@import "src/styles/colors";



.categories-container,
.feature-flags-details-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.categories-container {
  gap: 8px;
}

.categories-section-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
}

.category-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 10px;
}

.category-container,
.categories-section-container{

  &.with-separator {
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1.2px solid #DFE2E5;
  }
}

.sizes-list-wrapper {
  text-transform: uppercase;
  margin: 5px 0;

  th, td {
    min-width: 120px;
  }
}


.show-inventory-btn .text {
    text-transform: uppercase;
    font-weight: 500;
    padding-bottom: 6px;
    margin-top: 10px;
}

.inventory-update-title {
  display: inline-flex;
}

.inventory-update-date {
  margin-left: 4px;

  .rtl &{
    margin-left: 0;
    margin-right: 4px;
  }
}

.category-name {
  color: $primary-blue;
  text-transform: uppercase;
  width: 100%;
  text-align: left;
  font-size: 12px;
  line-height: 17px;
  @extend %font-weight-medium;

  .rtl &{
    text-align: right;
  }
}

.category-value {
  text-align: left;
  margin-top: 2px;
  color: $secondary-slate-gray;
  width: 100%;
  line-height: 19px;

  .rtl &{
    text-align: right;
  }
}
