@import "src/styles/colors";
@import "src/styles/mixins";
@import "src/styles/typography";

.panel-input {
  @extend %font-weight-regular, %font-md;
  color: $secondary-comet;
  width: 100%;
  height: 100%;
  border: none;
  word-wrap: break-word;
  outline: none;
  resize: none;
}

.panel-label-container {
  @include flexible(row, space-between, center);
  width: 100%;
  height: fit-content;
  cursor: pointer;
  background: none;
  padding: 0;
  transition: all .5s;
  border: none;
  color: #67808D;
}

.panel-label-container.active {
  background-color: $primary-cerulean;
  color: white;
}

.panel-button {
  @extend %font-weight-medium, %font-lg;
  width: 164px;
  height: 36px;
  border-radius: 4px;
  outline: none;
  border: none;
  margin-left: 8px;
  line-height: 16px;
  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
}
.submit-button {
  @extend .panel-button;
  color: white;
  background-color: $primary-cerulean;
}
.share-button {
  @extend .panel-button;
  border: 1px solid $primary-cerulean;
  background-color: white;
  color: $primary-cerulean;
}

.panel-label {
  @extend %font-lg, %font-weight-medium;
  outline: none;
  border: none;
  background-color: transparent;
  text-align: left;
  line-height: 22px;
  padding: 18px 12px;
  flex: 1;

  .rtl &{
    text-align: right;
  }
}

.panel-icon-container {
  @include flexible(column, center, center);
  height: 60px;
  width: 107px;
  outline: none;
  border: none;
  background-color: transparent;
  border-inline-start: 3px solid $secondary-geyser;
  direction: inherit;
}

.no_border {
  border-inline-start: none;
}
.panel-inner {
  overflow: hidden;
  height: 0;
  transition: height 0.4s cubic-bezier(0.65, 0.05, 0.36, 1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.panel-content {
  @extend %font-md, %font-weight-medium;
  color: $secondary-comet;
  white-space: pre-wrap;
  line-height: 19px;
  border-top: 3px solid $secondary-geyser;
  flex: 1;
  margin: 0;
  resize: none;
  padding: 12px;
  transition: opacity 0.3s linear 0.18s;
}

.panel-container {
  width: 100%;
  background-color: white;
  border-radius: 4px;
  margin-bottom: 6px;
}

.panel-buttons-container {
  @include flexible(row, flex-end, center);
  margin: 0 8px 8px 0;
}

.open-panel {
  height: 132px;
}
