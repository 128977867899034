@import "src/styles/colors";

.opportunity-popup-container {
  width: 1000px;
  display: flex;
  flex-direction: column;

  .popup-header {
    .preview-header-container {
      box-shadow: 0 1px 0 0 getColor($black, 0.1);

      .preview-details-container {
        .preview-header-footer{
          padding-left: 11px;

          .rtl & {
            padding-left: 0;
            padding-right: 11px;
          }
        }
        .preview-header-top {
          padding-left: 18px;

          .rtl & {
            padding-left: 0;
            padding-right: 18px;
          }

        }
      }
    }

    .preview-image-share-container .product-image {
      cursor: initial;

    }
  }

  .popup-content {
    .product-sale-data {
      display: flex;
      flex-direction: column;
      padding: 16px 18px 0;

      .product-sale-data-header {
        display: flex;
        align-items: center;

        .opportunity-toggle {
          background: $antBlue;
        }
      }

      .title {
        color: $primary-blue;
        flex: 0.55;
        font-size: 14px;
        text-transform: uppercase;
      }

      .chart-info {
        display: flex;
        margin-top: 10px;

        .info {
          flex:0.4;
          padding: 0 30px;
          overflow-y: auto;
          max-height: 400px;
          margin-bottom: 20px;
        }

        .chart {
          flex:0.6;
          box-shadow: 0 2px 4px 1px getColor($black, 0.1);
          padding-top: 12px;
          padding-bottom: 20px;
          height: max-content;

          .sub-title {
            color: $primary-blue;
            font-size: 12px;
            text-transform: uppercase;
            margin-bottom: 10px;
            padding: 0 25px;
          }

          .info {
            display: flex;
            padding: 0 25px;
            gap:10px;
          }
        }
      }
    }
  }
}
