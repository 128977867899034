@import 'src/styles/colors';

.side-menu-control {
  display: flex;
  flex-direction: column;
  position: fixed;
  margin: 0;
  padding: 0;
  width: 0;
  transition: width 0.4s linear;
  height: 100%;
  z-index: 750;
  background: $background-primary;
  right: 0;
  box-shadow: getColor($black, 0.08) 0 0 5px 3px;

  .rtl &{
    right: auto;
    left: 0;
  }
  .control-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    background: $primary-blue;
    color: $white;
    padding: 28px;

    .title {
      font-size: 20px;
    }

    .close {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px;
      cursor: pointer;
    }
  }

  &.expanded {
    display: flex;
    width: 620px;
    //visibility: visible;
    .separator {
      background-color: getColor($gray, 0);
    }
  }
}
